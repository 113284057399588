import React, { useState } from 'react';
import { Sparkles, MapPin, BarChart2, Lock } from 'lucide-react';
import { validInviteCodes } from './data';

const FeatureItem = ({ icon: Icon, title, description }) => (
  <div className="flex items-start mb-4">
    <div className="bg-blue-500 p-1.5 rounded-full mr-3 flex-shrink-0">
      <Icon className="text-white" size={18} />
    </div>
    <div>
      <h3 className="text-base font-semibold mb-0.5">{title}</h3>
      <p className="text-sm text-gray-600">{description}</p>
    </div>
  </div>
);

const FirstUseScreen = ({ onOpenAddMomentModal }) => {
  const [inviteCode, setInviteCode] = useState('');
  const [error, setError] = useState('');

  const handleContinue = () => {
    const normalizedCode = inviteCode.trim().toUpperCase();
    if (validInviteCodes.includes(normalizedCode)) {
      onOpenAddMomentModal();
    } else {
      setError('Invalid invite code');
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gradient-to-b from-pink-100 to-purple-100 p-4">
      <div className="bg-white rounded-3xl shadow-xl w-[460px] p-6">
        <div className="text-center mb-6">
          <h1 className="text-3xl font-bold mb-2">HumanBetter</h1>
          <p className="text-sm text-gray-600 mb-4">
          Create meaningful connections.
          </p>
        </div>

        <FeatureItem
          icon={Sparkles}
          title="AI-Powered Suggestions"
          description="Get personalized gift and activity ideas based on multi-dimensional analysis of your relationships."
        />

        <FeatureItem
          icon={MapPin}
          title="Location-Based Meetups"
          description="Discover perfect spots for real-life connections with smart, contextual recommendations."
        />

        <FeatureItem
          icon={BarChart2}
          title="Relationship Insights"
          description="Gain valuable analytics to understand and improve your most important connections."
        />

        <FeatureItem
          icon={Lock}
          title="Built for Privacy"
          description="Your data stays on your device, ensuring your personal information remains private and secure."
        />

        <div className="mt-6 mb-4">
          <div className="space-y-2">
            <label htmlFor="inviteCode" className="block text-sm font-medium text-gray-700">
              Enter Invitation Code
            </label>
            <input
              id="inviteCode"
              type="text"
              value={inviteCode}
              onChange={(e) => {
                setError('');
                setInviteCode(e.target.value);
              }}
              className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500"
              placeholder="Enter 6-digit code"
              maxLength={6}
            />
            {error && (
              <p className="text-red-500 text-sm">{error}</p>
            )}
          </div>
        </div>

        <div className="mt-6 flex flex-col">
          <button
            onClick={handleContinue}
            disabled={inviteCode.length < 6}
            className={`bg-blue-500 text-white py-2 px-4 rounded-full text-base font-semibold transition duration-300 mb-3 w-full
              ${inviteCode.length < 6 ? 'opacity-50 cursor-not-allowed' : 'hover:bg-blue-600'}`}
          >
            Continue
          </button>
        </div>
      </div>
    </div>
  );
};

export default FirstUseScreen;