import { TEMPLATES } from '../../services/notifications/templates';

const SAMPLE_DATA = {
  name: 'Sarah',
  date: '2024-04-15',
  momentType: 'Birthday',
  suggestion: 'Plan a surprise picnic',
  healthScore: '85',
  lastMoment: '2 weeks ago',
  upcomingMoments: '2 moments in next 30 days',
  personId: '123'
};

const TemplatePreview = ({ onBack }) => {
  const interpolateTemplate = (content, data) => {
    let interpolated = JSON.parse(JSON.stringify(content));
    Object.keys(data).forEach(key => {
      const placeholder = `{{${key}}}`;
      if (typeof interpolated === 'string') {
        interpolated = interpolated.replace(new RegExp(placeholder, 'g'), data[key]);
      } else if (typeof interpolated === 'object') {
        Object.keys(interpolated).forEach(field => {
          if (typeof interpolated[field] === 'string') {
            interpolated[field] = interpolated[field].replace(new RegExp(placeholder, 'g'), data[key]);
          }
        });
      }
    });
    return interpolated;
  };

  return (
    <div className="p-6 max-w-4xl mx-auto">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-bold">Notification Templates Preview</h1>
        <button 
          onClick={onBack}
          className="px-4 py-2 bg-indigo-600 text-white rounded-md hover:bg-indigo-700"
        >
          Back to App
        </button>
      </div>
      
      {Object.entries(TEMPLATES).map(([templateName, template]) => (
        <div key={templateName} className="mb-8 border rounded-lg p-4 bg-white shadow-sm">
          <h2 className="text-xl font-semibold mb-4">{templateName}</h2>
          <div className="text-sm text-gray-500 mb-2">Priority: {template.priority}</div>
          <div className="text-sm text-gray-500 mb-4">
            Channels: {template.channels.join(', ')}
          </div>
          
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            {/* In-App Preview */}
            {template.templates.inApp && (
              <div className="border rounded p-4">
                <h3 className="font-medium mb-2">In-App</h3>
                <div className="bg-white shadow rounded p-3">
                  <div className="font-medium">
                    {interpolateTemplate(template.templates.inApp.title, SAMPLE_DATA)}
                  </div>
                  <div className="text-gray-600 text-sm mt-1">
                    {interpolateTemplate(template.templates.inApp.message, SAMPLE_DATA)}
                  </div>
                </div>
              </div>
            )}

            {/* SMS Preview */}
            {template.templates.sms && (
              <div className="border rounded p-4">
                <h3 className="font-medium mb-2">SMS</h3>
                <div className="bg-gray-100 rounded p-3">
                  <div className="text-gray-800 text-sm">
                    {interpolateTemplate(template.templates.sms.message, SAMPLE_DATA)}
                  </div>
                </div>
              </div>
            )}

            {/* Email Preview */}
            {template.templates.email && (
              <div className="border rounded p-4 col-span-full">
                <h3 className="font-medium mb-2">Email</h3>
                <div className="bg-white shadow rounded p-3">
                  <div className="font-medium mb-2">
                    Subject: {interpolateTemplate(template.templates.email.subject, SAMPLE_DATA)}
                  </div>
                  <div className="text-sm text-gray-600">
                    Template ID: {template.templates.email.templateId}
                  </div>
                  <div className="mt-2 text-sm">
                    <h4 className="font-medium mb-1">Template Data:</h4>
                    <pre className="bg-gray-50 p-2 rounded">
                      {JSON.stringify(
                        Object.fromEntries(
                          Object.entries(template.templates.email.data).map(([key, value]) => [
                            key,
                            interpolateTemplate(value, SAMPLE_DATA)
                          ])
                        ),
                        null,
                        2
                      )}
                    </pre>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default TemplatePreview; 