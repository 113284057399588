export const TEMPLATES = {
  RELATIONSHIP_ALERT: {
    type: 'relationship_alert',
    priority: 'critical',
    channels: ['inApp', 'sms', 'email'],
    templates: {
      inApp: {
        title: 'Connection Needed',
        message: '{{name}} might need some attention'
      },
      sms: {
        message: '💝 {{name}} could use some connection time. Quick idea: {{suggestion}}'
      },
      email: {
        subject: 'Important: Relationship Needs Attention',
        templateId: 'relationship-alert',
        data: {
          personName: '{{name}}',
          healthScore: '{{healthScore}}',
          suggestions: '{{suggestion}}',
          lastMoment: '{{lastMoment}}'
        }
      }
    }
  },

  WEEKLY_PLANNING: {
    type: 'weekly_planning',
    priority: 'high',
    channels: ['inApp', 'email', 'sms'],
    templates: {
      inApp: {
        title: 'Plan Your Week',
        message: 'Set intentions for meaningful connections this week'
      },
      email: {
        subject: 'Your Weekly Connection Planner',
        templateId: 'weekly-planning',
        data: {
          upcomingMoments: '{{upcomingMoments}}',
          suggestions: '{{suggestions}}',
          relationshipInsights: '{{relationshipInsights}}'
        }
      },
      sms: {
        message: '📅 Time to plan your week of meaningful moments! Check your email for personalized suggestions.'
      }
    }
  },

  MOMENT_REMINDER: {
    type: 'moment_reminder',
    priority: 'high',
    channels: ['inApp', 'sms', 'email'],
    templates: {
      inApp: {
        title: 'Upcoming Moment',
        message: 'Remember your {{momentType}} with {{name}} on {{date}}'
      },
      sms: {
        message: '🎯 Upcoming: {{momentType}} with {{name}} on {{date}}'
      },
      email: {
        subject: 'Moment Reminder: {{momentType}} with {{name}}',
        templateId: 'moment-reminder',
        data: {
          name: '{{name}}',
          momentType: '{{momentType}}',
          date: '{{date}}',
          suggestion: '{{suggestion}}'
        }
      }
    }
  }
};

export default TEMPLATES; 