import React, { useState } from 'react';
import { Star } from 'lucide-react';

const RatingModal = ({ isOpen, onClose, moment, onRate }) => {
  const [rating, setRating] = useState(0);
  const [notes, setNotes] = useState('');

  if (!isOpen || !moment) return null;

  return (
    <div className="fixed inset-0 bg-black/50 flex items-center justify-center p-4 z-50">
      <div className="bg-white rounded-xl max-w-md w-full p-6">
        <h3 className="text-xl font-semibold mb-4">
          {moment.title}
        </h3>
        
        <div className="text-sm text-gray-500 mb-6">
          {new Date(moment.date).toLocaleDateString()}
        </div>

        <div className="text-center mb-6">
          <div className="text-sm text-gray-600 mb-2">Rate this moment:</div>
          <div className="flex justify-center gap-1">
            {[1, 2, 3, 4, 5].map((star) => (
              <button
                key={star}
                onClick={() => setRating(star)}
                className="p-1 transition-transform hover:scale-110"
              >
                <Star
                  size={32}
                  className={`${
                    star <= rating 
                      ? 'fill-yellow-400 text-yellow-400' 
                      : 'text-gray-300'
                  }`}
                />
              </button>
            ))}
          </div>
        </div>

        <textarea
          value={notes}
          onChange={(e) => setNotes(e.target.value)}
          placeholder="Add a note about this moment (optional)"
          className="w-full p-3 border rounded-lg mb-6 h-24 resize-none"
        />

        <div className="flex justify-end gap-2">
          <button
            onClick={onClose}
            className="px-4 py-2 text-gray-600 hover:bg-gray-100 rounded"
          >
            Skip
          </button>
          <button
            onClick={() => {
              onRate({ rating, notes });
              onClose();
            }}
            disabled={!rating}
            className={`px-4 py-2 rounded ${
              !rating
                ? 'bg-gray-200 text-gray-500'
                : 'bg-blue-600 text-white hover:bg-blue-700'
            }`}
          >
            Save Rating
          </button>
        </div>
      </div>
    </div>
  );
};

export default RatingModal; 