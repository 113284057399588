import React from 'react';
import { Heart, TrendingUp, TrendingDown, Minus, User } from 'lucide-react';
import { calculateHealthScore, getHealthLevel, HEALTH_LEVELS } from './utils/relationshipHealthUtils';

const getHealthColor = (score) => {
  if (score <= 3) return 'text-red-500';
  if (score <= 6) return 'text-yellow-500';
  if (score <= 8) return 'text-green-500';
  return 'text-emerald-500';
};

const HealthIndicator = ({ score, previousScore }) => {
  const healthLevel = getHealthLevel(score);
  
  return (
    <div className="group relative">
      <div className="flex items-center gap-1.5 bg-white/90 backdrop-blur-sm rounded-full px-2 py-1 shadow-sm">
        <Heart 
          size={16} 
          className={`${getHealthColor(score)} ${score > 3 ? 'fill-current' : 'fill-none'}`} 
        />
        <span className={`text-xs font-medium ${getHealthColor(score)}`}>
          {score.toFixed(1)}
        </span>
        <span className="text-sm ml-1">{healthLevel.icon}</span>
      </div>

      <div className="invisible group-hover:visible absolute right-0 top-full mt-2 w-64 p-4 bg-gray-800 text-white text-sm rounded-lg shadow-lg z-10">
        <div className="flex items-center gap-2 mb-3">
          <span className="text-2xl">{healthLevel.icon}</span>
          <div>
            <h4 className="font-medium">{healthLevel.name}</h4>
            <p className="text-xs text-gray-300">{score.toFixed(1)}/10</p>
          </div>
        </div>
        
        <p className="text-sm mb-3">{healthLevel.description}</p>
        
        <div className="bg-gray-700 rounded-lg p-3 mt-3">
          <p className="text-xs font-medium text-gray-300">NEXT GOAL</p>
          <p className="text-sm mt-1">{healthLevel.nextGoal}</p>
        </div>

        <div className="mt-4 pt-3 border-t border-gray-700">
          <p className="text-xs font-medium text-gray-300 mb-2">WHAT AFFECTS THIS SCORE:</p>
          <ul className="text-xs text-gray-300 space-y-1.5">
            <li className="flex items-start gap-2">
              <span className="text-green-400">+</span>
              <span>Regular moments and interactions</span>
            </li>
            <li className="flex items-start gap-2">
              <span className="text-green-400">+</span>
              <span>High-rated past moments (4-5 stars)</span>
            </li>
            <li className="flex items-start gap-2">
              <span className="text-green-400">+</span>
              <span>Multiple moments in the last week</span>
            </li>
            <li className="flex items-start gap-2">
              <span className="text-red-400">−</span>
              <span>Low-rated moments (1-2 stars)</span>
            </li>
            <li className="flex items-start gap-2">
              <span className="text-red-400">−</span>
              <span>Long periods without interaction</span>
            </li>
            <li className="flex items-start gap-2">
              <span className="text-yellow-400">~</span>
              <span>Unrated moments have a small negative impact</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

const PeopleCards = ({ people, onPersonClick }) => {
  return (
    <div className="grid grid-cols-2 gap-4 sm:grid-cols-3 lg:grid-cols-4">
      {people.map((person) => {
        // Make sure we're calling calculateHealthScore correctly
        const healthScore = calculateHealthScore(person) || 1; // Fallback to 1 if calculation fails
        const previousHealthScore = person.previousHealthScore;
        
        return (
          <div 
            key={person.id}
            className="relative bg-white rounded-xl p-4 shadow-sm hover:shadow-md transition-shadow cursor-pointer"
            onClick={() => onPersonClick(person)}
          >
            <div className="absolute top-2 right-2">
              <HealthIndicator 
                score={healthScore} 
                previousScore={previousHealthScore}
              />
            </div>
            
            <div className="flex flex-col items-center">
              {person.image ? (
                <img 
                  src={person.image} 
                  alt={person.name}
                  className="w-20 h-20 rounded-full object-cover"
                />
              ) : (
                <div className="w-20 h-20 rounded-full bg-gray-100 flex items-center justify-center">
                  <User className="w-10 h-10 text-gray-400" />
                </div>
              )}
              <h3 className="mt-2 font-medium text-gray-900">{person.name}</h3>
              <p className="text-sm text-gray-500">{person.relationship}</p>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default PeopleCards;