import React, { useState, useEffect } from 'react';
import { Calendar, UserPlus, RefreshCw } from 'lucide-react';
import { v4 as uuidv4 } from 'uuid';
import { getRandomAction } from './generateMomentSuggestions';
import { EFFORT_LEVELS } from './data';

const AddMomentModal = ({ isOpen, onClose, onAddMoment, people, momentTypes, prefillData, isFirstUse }) => {
  const [date, setDate] = useState('');
  const [momentType, setMomentType] = useState('');
  const [person, setPerson] = useState('');
  const [newPerson, setNewPerson] = useState('');
  const [isAddingNewPerson, setIsAddingNewPerson] = useState(isFirstUse);
  const [notes, setNotes] = useState('');
  const [effortLevel, setEffortLevel] = useState('');
  const [suggestionText, setSuggestionText] = useState('');
  const [suggestions, setSuggestions] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [previousSuggestions, setPreviousSuggestions] = useState({});

  useEffect(() => {
    if (prefillData) {
      setDate(prefillData.date || '');
      setMomentType(prefillData.momentType || '');
      setPerson(prefillData.person || '');
      setSuggestionText(prefillData.suggestion || '');
      setNotes(prefillData.suggestion || prefillData.notes || '');
      setEffortLevel(prefillData.effortLevel || '');
      setIsAddingNewPerson(isFirstUse);
    }
  }, [prefillData, isFirstUse]);

  useEffect(() => {
    if (momentType && person) {
      const selectedPerson = people.find(p => p.name === person);
      if (selectedPerson?.loveLanguage?.importance) {
        const primaryLanguage = Object.entries(selectedPerson.loveLanguage.importance)
          .sort(([, a], [, b]) => b - a)[0][0];

        const suggestionsByEffort = {};
        Object.keys(EFFORT_LEVELS).forEach(level => {
          const suggestion = getRandomAction(primaryLanguage, level);
          if (suggestion) {
            suggestionsByEffort[level] = suggestion;
          }
        });

        setSuggestions(suggestionsByEffort);
        setPreviousSuggestions(prev => ({
          ...prev,
          ...Object.entries(suggestionsByEffort).reduce((acc, [level, suggestion]) => ({
            ...acc,
            [level]: [...(prev[level] || []), suggestion]
          }), {})
        }));
      }
    }
  }, [momentType, person, people]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const moment = {
      id: uuidv4(),
      date,
      momentType,
      person: isAddingNewPerson ? newPerson : person,
      notes: suggestionText || notes,
      title: suggestionText || notes,
      effortLevel,
      isPastEvent: false
    };

    onAddMoment(moment);
    resetForm();
    onClose();
  };

  const resetForm = () => {
    setDate('');
    setMomentType('');
    setPerson('');
    setNewPerson('');
    setIsAddingNewPerson(isFirstUse);
    setNotes('');
    setEffortLevel('');
    setSuggestionText('');
    setSuggestions({});
  };

  const handleRefreshSuggestions = () => {
    if (momentType && person) {
      const selectedPerson = people.find(p => p.name === person);
      if (selectedPerson?.loveLanguage?.importance) {
        const primaryLanguage = Object.entries(selectedPerson.loveLanguage.importance)
          .sort(([, a], [, b]) => b - a)[0][0];

        const suggestionsByEffort = {};
        Object.keys(EFFORT_LEVELS).forEach(level => {
          let newSuggestion;
          let attempts = 0;
          const maxAttempts = 10; // Prevent infinite loop

          // Keep trying until we get a new suggestion or run out of attempts
          do {
            newSuggestion = getRandomAction(primaryLanguage, level);
            attempts++;
          } while (
            newSuggestion && 
            previousSuggestions[level]?.includes(newSuggestion) && 
            attempts < maxAttempts
          );

          if (newSuggestion) {
            suggestionsByEffort[level] = newSuggestion;
          }
        });

        setSuggestions(suggestionsByEffort);
        setPreviousSuggestions(prev => ({
          ...prev,
          ...Object.entries(suggestionsByEffort).reduce((acc, [level, suggestion]) => ({
            ...acc,
            [level]: [...(prev[level] || []), suggestion]
          }), {})
        }));
      }
    }
  };

  if (!isOpen) return null;

  const inputClass = "w-full p-2 border border-gray-300 rounded-md text-sm text-gray-700 placeholder-gray-400";

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-3xl w-full max-w-md mx-4 overflow-hidden">
        <div className="bg-gray-100 p-4">
          <h3 className="text-xl font-semibold">Add a Moment</h3>
        </div>
        <form onSubmit={handleSubmit} className="p-6 space-y-4">
          {/* Human Selection First */}
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1" htmlFor="person">
              Human
            </label>
            {isAddingNewPerson || isFirstUse ? (
              <input
                type="text"
                id="newPerson"
                value={newPerson}
                onChange={(e) => setNewPerson(e.target.value)}
                className={inputClass}
                placeholder="Enter new human's name"
                required
              />
            ) : (
              <select
                id="person"
                value={person}
                onChange={(e) => setPerson(e.target.value)}
                className={inputClass}
                required
              >
                <option value="">Select your human</option>
                {people.map((p, index) => (
                  <option key={index} value={p.name}>{p.name}</option>
                ))}
              </select>
            )}
            {!isFirstUse && (
              <button
                type="button"
                onClick={() => setIsAddingNewPerson(!isAddingNewPerson)}
                className="mt-2 text-blue-500 hover:text-blue-700 flex items-center text-sm"
              >
                <UserPlus size={16} className="mr-1" />
                {isAddingNewPerson ? "Select Existing Human" : "Add New Human"}
              </button>
            )}
          </div>

          {/* Date Selection */}
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1" htmlFor="date">
              Date
            </label>
            <div className="relative">
              <input
                type="date"
                id="date"
                value={date}
                onChange={(e) => setDate(e.target.value)}
                className={`${inputClass} pl-10`}
                required
              />
              <Calendar className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={20} />
            </div>
          </div>

          {/* Moment Type with Suggestions */}
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1" htmlFor="momentType">
              Moment Type
            </label>
            <select
              id="momentType"
              value={momentType}
              onChange={(e) => setMomentType(e.target.value)}
              className={inputClass}
              required
            >
              <option value="">Select a type of Moment</option>
              {momentTypes.map((type, index) => (
                <option key={index} value={type}>{type}</option>
              ))}
            </select>

            {/* Suggestions Section */}
            {Object.keys(suggestions).length > 0 && (
              <div className="mt-4 space-y-2">
                <div className="flex justify-between items-center">
                  <label className="block text-sm font-medium text-gray-700">
                    Suggested Activities
                  </label>
                  <button
                    type="button"
                    onClick={handleRefreshSuggestions}
                    disabled={isLoading}
                    className={`text-gray-500 hover:text-gray-700 p-1 rounded-full hover:bg-gray-100 transition-colors ${
                      isLoading ? 'opacity-50 cursor-not-allowed' : ''
                    }`}
                  >
                    <RefreshCw 
                      size={16} 
                      className={`${isLoading ? 'animate-spin' : ''}`}
                    />
                  </button>
                </div>
                <div className="space-y-2">
                  {Object.entries(suggestions).map(([level, suggestion]) => (
                    <button
                      key={level}
                      type="button"
                      onClick={() => {
                        setNotes(suggestion);
                        setEffortLevel(level);
                      }}
                      className="w-full text-left p-3 rounded-md text-sm transition-colors"
                      style={{
                        backgroundColor: EFFORT_LEVELS[level].color,
                        opacity: 0.8,
                        hover: { opacity: 1 }
                      }}
                    >
                      <div className="flex justify-between items-center">
                        <span>{suggestion}</span>
                        <span className="text-xs">
                          {EFFORT_LEVELS[level].description}
                        </span>
                      </div>
                    </button>
                  ))}
                </div>
              </div>
            )}
          </div>

          {/* Notes */}
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1" htmlFor="notes">
              Activity
            </label>
            <textarea
              id="notes"
              value={notes}
              onChange={(e) => setNotes(e.target.value)}
              className={inputClass}
              rows="3"
              placeholder="Message"
            />
          </div>

          <input type="hidden" value={effortLevel} />

          {/* Action Buttons */}
          <button
            type="submit"
            className="w-full bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600 transition duration-200"
          >
            Add Moment
          </button>
          <button
            type="button"
            onClick={() => {
              resetForm();
              onClose();
            }}
            className="w-full bg-gray-200 text-gray-800 py-2 px-4 rounded-md hover:bg-gray-300 transition duration-200"
          >
            Cancel
          </button>
        </form>
      </div>
    </div>
  );
};

export default AddMomentModal;