import React from 'react';

const PeoplePage = ({ 
  people, 
  onAddPerson,
  onPersonClick,
  onAddMoment 
}) => {
  return (
    <div className="mb-8">
      <h2 className="text-sm font-medium text-gray-700 mb-2">People</h2>
      <div className="grid grid-cols-2 gap-4">
        {people.map(person => (
          <div
            key={person.id}
            onClick={() => onPersonClick(person)}
            className="bg-white p-4 rounded-lg shadow-sm cursor-pointer hover:shadow-md transition-shadow"
          >
            <h3 className="font-medium">{person.name}</h3>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PeoplePage;