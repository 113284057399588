import React, { useState, useRef } from 'react';
import { Calendar, Gift, Coffee, Heart, ChevronLeft, ChevronRight, Clock, Users, Puzzle, Star, MessageCircle } from 'lucide-react';
import { format, addDays } from 'date-fns';
import { EFFORT_LEVELS } from './data';

const SUGGESTION_TYPE_ICONS = {
  'time-based': Clock,
  'love-language': Heart,
  'interest-based': Puzzle,
  'special-occasion': Gift,
  'recovery': Star,
  'maintenance': Users,
  'recent-connection': MessageCircle
};

const MomentSuggestions = ({ suggestions, onSuggestionAction }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const containerRef = useRef(null);

  const handleScroll = (direction) => {
    const newIndex = direction === 'left' 
      ? Math.max(0, currentIndex - 1)
      : Math.min(suggestions.length - 1, currentIndex + 1);
    
    setCurrentIndex(newIndex);
  };

  const getIconComponent = (type) => {
    const IconComponent = SUGGESTION_TYPE_ICONS[type];
    return IconComponent ? <IconComponent className="w-6 h-6" /> : <Coffee className="w-6 h-6" />;
  };

  const getLoveLanguageEmoji = (loveLanguage) => {
    const emojis = {
      words: '💭',
      time: '⌛',
      gifts: '🎁',
      service: '🤝',
      touch: '💝',
      general: '❤️'
    };
    return emojis[loveLanguage] || emojis.general;
  };

  const handleSuggestionClick = (suggestion) => {
    const suggestionWithDate = suggestion.date
      ? suggestion
      : {
          ...suggestion,
          date: format(addDays(new Date(), 1), 'yyyy-MM-dd'),
        };
    onSuggestionAction(suggestionWithDate);
  };

  if (!suggestions || suggestions.length === 0) {
    return (
      <div className="text-center p-4">
        <p className="text-gray-600">No suggestions available at the moment.</p>
      </div>
    );
  }

  return (
    <div className="relative h-[200px] overflow-hidden">
      {currentIndex > 0 && (
        <button
          onClick={() => handleScroll('left')}
          className="absolute left-0 top-1/2 transform -translate-y-1/2 z-10 
            bg-white rounded-full p-2 shadow-lg hover:bg-gray-50 transition-colors"
          aria-label="Previous suggestion"
        >
          <ChevronLeft size={24} className="text-indigo-600" />
        </button>
      )}

      <div 
        ref={containerRef}
        className="flex space-x-3 h-full px-2 transition-transform duration-300 ease-in-out"
        style={{ 
          transform: `translateX(-${currentIndex * 271}px)` // 268px card width + 3px spacing
        }}
      >
        {suggestions.map((suggestion, index) => {
          const effortLevel = suggestion.effortLevel || 'micro';
          const backgroundColor = EFFORT_LEVELS[effortLevel]?.color || EFFORT_LEVELS.micro.color;
          const isLightColor = ['micro', 'mini'].includes(effortLevel);

          return (
            <div 
              key={index} 
              className="rounded-3xl p-4 flex-shrink-0 flex flex-col justify-between cursor-pointer
                transform transition-transform duration-200 hover:scale-102 hover:shadow-lg"
              style={{ 
                width: '268px',
                height: '200px',
                backgroundColor,
                color: isLightColor ? '#1F2937' : 'white'
              }}
              onClick={() => handleSuggestionClick(suggestion)}
              role="button"
              aria-label={`Suggestion: ${suggestion.suggestion}`}
            >
              <div className="flex items-start space-x-3">
                <div className={`rounded-full bg-opacity-20 bg-white p-2 ${
                  isLightColor ? 'text-gray-800' : 'text-white'
                }`}>
                  {getIconComponent(suggestion.type)}
                </div>
                <div className="flex-1">
                  <p className="text-sm font-medium mb-1 line-clamp-3">
                    {suggestion.suggestion}
                  </p>
                  {suggestion.date && (
                    <p className="text-xs opacity-75">
                      {format(new Date(suggestion.date), 'MMM d, yyyy')}
                    </p>
                  )}
                </div>
              </div>

              <div className="space-y-2">
                <div className="flex items-center justify-between text-xs">
                  <div className="flex items-center space-x-2">
                    <span>{getLoveLanguageEmoji(suggestion.loveLanguage)}</span>
                    <span className="capitalize opacity-75">
                      {suggestion.loveLanguage || 'general'}
                    </span>
                  </div>
                  <div className="flex items-center space-x-1">
                    <Clock size={12} />
                    <span className="opacity-75">
                      {EFFORT_LEVELS[effortLevel].description}
                    </span>
                  </div>
                </div>

                {suggestion.relationshipHealth && (
                  <div className="mt-2 h-1 bg-white bg-opacity-20 rounded-full overflow-hidden">
                    <div 
                      className="h-full bg-white rounded-full transition-all duration-300"
                      style={{ 
                        width: `${suggestion.relationshipHealth * 10}%`,
                        opacity: 0.8 
                      }}
                    />
                  </div>
                )}
              </div>
            </div>
          );
        })}
      </div>

      {currentIndex < suggestions.length - 1 && (
        <button
          onClick={() => handleScroll('right')}
          className="absolute right-0 top-1/2 transform -translate-y-1/2 z-10 
            bg-white rounded-full p-2 shadow-lg hover:bg-gray-50 transition-colors"
          aria-label="Next suggestion"
        >
          <ChevronRight size={24} className="text-indigo-600" />
        </button>
      )}

      <div className="absolute bottom-2 left-1/2 transform -translate-x-1/2 flex space-x-1">
        {suggestions.map((_, index) => (
          <div
            key={index}
            className={`h-1 rounded-full transition-all duration-200 ${
              index === currentIndex ? 'w-4 bg-indigo-600' : 'w-1 bg-gray-300'
            }`}
          />
        ))}
      </div>
    </div>
  );
};

export default MomentSuggestions;