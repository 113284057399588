import React, { useState, useEffect } from 'react';
import { X } from 'lucide-react';

const SettingsModal = ({ isOpen, onClose, userData, onUpdateUserData, onClearAllData }) => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    zipCode: '',
    analyticsConsent: false
  });
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);

  // Update form data when modal opens or userData changes
  useEffect(() => {
    if (userData) {
      console.log('Setting form data from userData:', userData);
      setFormData({
        name: userData.name || '',
        email: userData.email || '',
        zipCode: userData.zipCode || '',
        analyticsConsent: userData.analyticsConsent || false
      });
    }
  }, [userData, isOpen]);

  const handleSubmit = (e) => {
    e.preventDefault();
    onUpdateUserData(formData);
    onClose();
  };

  const handleClearData = () => {
    setShowConfirmDialog(true);
  };

  const handleConfirmClear = () => {
    onClearAllData();
    onClose();
    setShowConfirmDialog(false);
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-3xl shadow-xl w-[460px] p-8 max-h-[90vh] overflow-y-auto relative">
        <button 
          onClick={onClose}
          className="absolute top-4 right-4 text-gray-500 hover:text-gray-700"
        >
          <X size={24} />
        </button>

        <h1 className="text-2xl font-bold text-center mb-6">Settings</h1>

        <form onSubmit={handleSubmit}>
          <div className="space-y-4">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Name
              </label>
              <input
                type="text"
                value={formData.name}
                onChange={(e) => setFormData(prev => ({ ...prev, name: e.target.value }))}
                className="w-full p-2 border border-gray-300 rounded-md"
                placeholder="Your name"
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Email
              </label>
              <input
                type="email"
                value={formData.email}
                onChange={(e) => setFormData(prev => ({ ...prev, email: e.target.value }))}
                className="w-full p-2 border border-gray-300 rounded-md"
                placeholder="your.email@example.com"
              />
            </div>
            
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Zip Code
              </label>
              <input
                type="text"
                value={formData.zipCode}
                onChange={(e) => setFormData(prev => ({ ...prev, zipCode: e.target.value }))}
                className="w-full p-2 border border-gray-300 rounded-md"
                placeholder="12345"
              />
            </div>

            <div className="mb-4">
              <label className="flex items-start space-x-2 cursor-pointer">
                <input
                  type="checkbox"
                  checked={formData.analyticsConsent}
                  onChange={(e) => setFormData(prev => ({
                    ...prev,
                    analyticsConsent: e.target.checked
                  }))}
                  className="mt-1"
                />
                <span className="text-sm text-gray-600">
                  Allow humanBetter to collect analytics data to improve the service
                </span>
              </label>
            </div>

            <button
              type="submit"
              className="w-full bg-indigo-600 text-white rounded-md py-2 hover:bg-indigo-700 transition-colors"
            >
              Save Changes
            </button>
          </div>
        </form>

        <div className="mt-8 pt-6 border-t border-gray-200">
          <h2 className="text-lg font-semibold text-red-600 mb-4">Danger Zone</h2>
          <button
            onClick={handleClearData}
            className="w-full bg-red-100 text-red-600 rounded-md py-2 hover:bg-red-200 transition-colors"
          >
            Delete humanBetter App Data
          </button>
        </div>

        {showConfirmDialog && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
            <div className="bg-white rounded-lg p-6 max-w-sm mx-4">
              <h3 className="text-lg font-semibold mb-4">Confirm Delete</h3>
              <p className="text-gray-600 mb-6">
                Are you sure you want to delete all app data? This action cannot be undone.
              </p>
              <div className="flex space-x-4">
                <button
                  onClick={() => setShowConfirmDialog(false)}
                  className="flex-1 px-4 py-2 bg-gray-100 text-gray-700 rounded-md hover:bg-gray-200 transition-colors"
                >
                  Cancel
                </button>
                <button
                  onClick={handleConfirmClear}
                  className="flex-1 px-4 py-2 bg-red-600 text-white rounded-md hover:bg-red-700 transition-colors"
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SettingsModal; 