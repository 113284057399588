import { 
  differenceInDays, 
  addDays, 
  startOfDay, 
  parseISO,
  format 
} from 'date-fns';
import { 
  wordsActions, 
  timeActions, 
  giftActions, 
  serviceActions, 
  touchActions,
  EFFORT_LEVELS,
  SUGGESTION_TYPES
} from './data';

const shouldAddTimeBasedSuggestion = (person) => {
  if (!person.moments || person.moments.length === 0) return true;
  
  const lastMoment = person.moments.sort((a, b) => 
    new Date(b.date) - new Date(a.date)
  )[0];
  
  const daysSinceLastMoment = differenceInDays(
    new Date(),
    parseISO(lastMoment.date)
  );
  
  return daysSinceLastMoment > 14;
};

const generateMomentSuggestions = (people, currentDate = new Date()) => {
  const suggestions = [];

  people.forEach(person => {
    const healthScore = calculateHealthScore(person);
    
    // Add time-based suggestions
    if (shouldAddTimeBasedSuggestion(person)) {
      suggestions.push(generateTimeBasedSuggestion(person, healthScore));
    }

    // Add love language suggestions
    const loveSuggestions = generateLoveLanguageSuggestions(person, healthScore);
    suggestions.push(...loveSuggestions);

    // Add interest-based suggestions
    if (person.interests?.length > 0) {
      suggestions.push(generateInterestBasedSuggestion(person, healthScore));
    }

    // Add recovery suggestions for low health scores
    if (healthScore < 7) {
      suggestions.push(generateRecoverySuggestion(person, healthScore));
    }

    // Add maintenance suggestions
    suggestions.push(generateMaintenanceSuggestion(person, healthScore));
  });

  return diversifyAndPrioritizeSuggestions(suggestions);
};

const calculateHealthScore = (person) => {
  const momentFrequency = person.moments?.length || 0;
  const recentMoments = person.moments?.filter(moment => 
    differenceInDays(new Date(), parseISO(moment.date)) <= 30
  ).length || 0;
  
  // Calculate average rating of past moments
  const pastRatings = (person.pastEvents || [])
    .filter(event => event.rating)
    .map(event => event.rating);
  
  const averageRating = pastRatings.length > 0
    ? pastRatings.reduce((sum, rating) => sum + rating, 0) / pastRatings.length
    : 0;

  // Incorporate ratings into health score
  const ratingBonus = averageRating * 0.5; // Ratings can boost score by up to 2.5 points
  
  return Math.min(10, Math.max(1, (momentFrequency + recentMoments) / 3 + ratingBonus));
};

const generateTimeBasedSuggestion = (person, healthScore) => {
  const effort = getEffortLevelBasedOnHealth(person, healthScore);
  return {
    type: SUGGESTION_TYPES.RECENT_CONNECTION,
    suggestion: `Connect with ${person.name} through ${getRandomAction('time', effort)}`,
    effortLevel: effort,
    loveLanguage: 'time',
    relationshipHealth: healthScore,
    personId: person.id,
    date: format(addDays(new Date(), 1), 'yyyy-MM-dd')
  };
};

const generateLoveLanguageSuggestions = (person, healthScore) => {
  const primaryLanguage = getPrimaryLoveLanguage(person);
  const effort = getEffortLevelBasedOnHealth(person, healthScore);
  const { types } = getPreferredMomentTypes(person);
  
  // Validate effort level
  if (!EFFORT_LEVELS[effort]) {
    console.warn(`Invalid effort level: ${effort}, falling back to 'mini'`);
    effort = 'mini';
  }

  const suggestions = [];
  if (types.length > 0) {
    suggestions.push({
      type: SUGGESTION_TYPES.LOVE_LANGUAGE,
      suggestion: getRandomAction(primaryLanguage, effort),
      effortLevel: effort,
      loveLanguage: primaryLanguage,
      relationshipHealth: healthScore,
      personId: person.id,
      date: format(addDays(new Date(), 2), 'yyyy-MM-dd'),
      confidence: 'high',
      basedOn: 'Suggested based on past successful moments'
    });
  }

  return suggestions;
};

const generateInterestBasedSuggestion = (person, healthScore) => {
  const randomInterest = person.interests[Math.floor(Math.random() * person.interests.length)];
  return {
    type: SUGGESTION_TYPES.INTEREST_BASED,
    suggestion: `Share their interest in ${randomInterest} through ${getRandomAction('time', 'medium')}`,
    effortLevel: 'medium',
    loveLanguage: 'time',
    relationshipHealth: healthScore,
    personId: person.id,
    date: format(addDays(new Date(), 3), 'yyyy-MM-dd')
  };
};

const generateRecoverySuggestion = (person, healthScore) => {
  return {
    type: SUGGESTION_TYPES.RECOVERY,
    suggestion: `Rebuild connection through ${getRandomAction('words', 'major')}`,
    effortLevel: 'major',
    loveLanguage: 'words',
    relationshipHealth: healthScore,
    personId: person.id,
    date: format(addDays(new Date(), 1), 'yyyy-MM-dd')
  };
};

const generateMaintenanceSuggestion = (person, healthScore) => {
  return {
    type: SUGGESTION_TYPES.MAINTENANCE,
    suggestion: `Maintain your connection through ${getRandomAction('service', 'mini')}`,
    effortLevel: 'mini',
    loveLanguage: 'service',
    relationshipHealth: healthScore,
    personId: person.id,
    date: format(addDays(new Date(), 4), 'yyyy-MM-dd')
  };
};

export const getRandomAction = (loveLanguage, effort) => {
  const actions = {
    words: wordsActions,
    time: timeActions,
    gifts: giftActions,
    service: serviceActions,
    touch: touchActions
  };

  const actionsList = actions[loveLanguage]?.[effort] || [];
  return actionsList[Math.floor(Math.random() * actionsList.length)];
};

const diversifyAndPrioritizeSuggestions = (suggestions) => {
  // Validate effort levels before sorting
  const validatedSuggestions = suggestions.map(suggestion => {
    if (!EFFORT_LEVELS[suggestion.effortLevel]) {
      console.warn(`Invalid effort level found: ${suggestion.effortLevel}, using 'mini'`);
      return { ...suggestion, effortLevel: 'mini' };
    }
    return suggestion;
  });

  // Sort by confidence (based on ratings) first
  const sortedSuggestions = validatedSuggestions.sort((a, b) => {
    if (a.confidence === 'high' && b.confidence !== 'high') return -1;
    if (b.confidence === 'high' && a.confidence !== 'high') return 1;
    return 0;
  });

  const diversified = [];
  const effortLevels = Object.keys(EFFORT_LEVELS);
  
  // Ensure we have a mix of effort levels, prioritizing high-confidence suggestions
  effortLevels.forEach(level => {
    const suggestion = sortedSuggestions.find(s => 
      s.effortLevel === level && !diversified.includes(s)
    );
    if (suggestion) diversified.push(suggestion);
  });

  // Fill remaining slots with other suggestions
  while (diversified.length < 5 && sortedSuggestions.length > diversified.length) {
    const remaining = sortedSuggestions.find(s => !diversified.includes(s));
    if (remaining) diversified.push(remaining);
  }

  return diversified;
};

const getPrimaryLoveLanguage = (person) => {
  if (!person.loveLanguage?.importance) return 'time';
  
  return Object.entries(person.loveLanguage.importance)
    .sort(([, a], [, b]) => b - a)[0][0];
};

const getEffortLevelBasedOnHealth = (person, healthScore) => {
  // Get preferred effort levels from highly-rated moments
  const { effortLevels } = getPreferredMomentTypes(person);
  
  // If we have preferred effort levels, use the most successful one
  if (effortLevels.length > 0 && EFFORT_LEVELS[effortLevels[0]]) {
    return effortLevels[0];
  }

  // Fall back to health-based effort levels
  if (healthScore <= 3) return 'micro';
  if (healthScore <= 6) return 'mini';
  if (healthScore <= 8) return 'medium';
  return 'major';
};

const getPreferredMomentTypes = (person) => {
  // Analyze highly-rated past moments
  const highlyRatedMoments = (person.pastEvents || [])
    .filter(event => event.rating >= 4)
    .reduce((acc, event) => {
      acc[event.momentType] = (acc[event.momentType] || 0) + 1;
      acc[event.effortLevel] = (acc[event.effortLevel] || 0) + 1;
      return acc;
    }, {});

  return {
    types: Object.entries(highlyRatedMoments)
      .sort(([, a], [, b]) => b - a)
      .map(([type]) => type),
    effortLevels: Object.entries(highlyRatedMoments)
      .sort(([, a], [, b]) => b - a)
      .map(([level]) => level)
  };
};

export default generateMomentSuggestions;