import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { format, addDays } from 'date-fns';
import FirstUseScreen from './FirstUseScreen';
import UserOnboardingScreen from './UserOnboardingScreen';
import PersonDetailsModal from './PersonDetailsModal';
import MomentSuggestions from './MomentSuggestions';
import generateMomentSuggestions from './generateMomentSuggestions';
import OnboardingRecommendations from './components/OnboardingRecommendations';

const OnboardingFlow = ({ onComplete, onUpdatePerson }) => {
  const [step, setStep] = useState('first-use');
  const [userData, setUserData] = useState({});
  const [personData, setPersonData] = useState({
    id: Date.now().toString(),
    name: '',
    relationship: '',
    interests: [],
    favoriteFoods: [],
    favoriteBrands: [],
    favoriteFlower: '',
    loveLanguage: {
      happinessAction: '',
      showingLove: [],
      comfortAction: '',
      importance: {
        words: 1,
        time: 1,
        help: 1,
        touch: 1,
        gifts: 1
      }
    }
  });

  useEffect(() => {
    console.log('OnboardingFlow rendered. Current step:', step);
    console.log('Current userData:', userData);
    console.log('Current personData:', personData);
  }, [step, userData, personData]);

  useEffect(() => {
    if (personData.name) {
      const storedPeople = JSON.parse(localStorage.getItem('stoikPeople') || '[]');
      const existingPersonIndex = storedPeople.findIndex(p => p.id === personData.id);
      
      if (existingPersonIndex !== -1) {
        storedPeople[existingPersonIndex] = personData;
      } else {
        storedPeople.push(personData);
      }
      
      localStorage.setItem('stoikPeople', JSON.stringify(storedPeople));
      console.log('Updated local storage with person data');
    }
  }, [personData]);

  const steps = {
    'first-use': { component: FirstUseScreen, props: { onOpenAddMomentModal: () => setStep('user-onboarding') } },
    'user-onboarding': { component: UserOnboardingScreen, props: { onSubmit: handleUserDataSubmit } },
    'person-details-main': { 
      component: PersonDetailsModal, 
      props: { 
        person: personData, 
        onStepComplete: handlePersonMainComplete,
        isOnboarding: true,
        isOpen: true,
        onClose: () => {},
        activeTab: 'main',
      } 
    },
    'person-details-what-they-like': { 
      component: PersonDetailsModal, 
      props: { 
        person: personData, 
        onStepComplete: handlePersonWhatTheyLikeComplete,
        isOnboarding: true,
        isOpen: true,
        onClose: () => {},
        activeTab: 'whatTheyLike',
      } 
    },
    'recommendations': { component: RecommendationsStep, props: { userData, personData, onComplete: handleOnboardingComplete } },
  };

  function handleUserDataSubmit(data) {
    console.log('User data submitted:', data);
    setUserData(data);
    setStep('person-details-main');
  }

  function handlePersonMainComplete(data) {
    console.log('Person main details completed:', data);
    const updatedPersonData = { ...personData, ...data };
    setPersonData(updatedPersonData);
    setStep('person-details-what-they-like');
  }

  function handlePersonWhatTheyLikeComplete(data) {
    console.log('Person "What They Like" details completed:', data);
    const updatedPersonData = { ...personData, ...data };
    setPersonData(updatedPersonData);
    if (onUpdatePerson) {
      onUpdatePerson(updatedPersonData);
    }
    setStep('recommendations');
  }

  function handleOnboardingComplete(selectedSuggestion) {
    console.log('Onboarding completed. Selected suggestion:', selectedSuggestion);
    
    let finalPersonData = personData;
    let formattedSuggestion = null;

    if (selectedSuggestion) {
      const suggestionDate = selectedSuggestion.date 
        ? new Date(selectedSuggestion.date) 
        : addDays(new Date(), 1);
      
      formattedSuggestion = {
        ...selectedSuggestion,
        date: format(suggestionDate, 'yyyy-MM-dd'),
        id: Date.now().toString(),
      };
    }

    const storedPeople = JSON.parse(localStorage.getItem('stoikPeople') || '[]');
    const updatedPeople = storedPeople.map(p => p.id === finalPersonData.id ? finalPersonData : p);
    if (!updatedPeople.some(p => p.id === finalPersonData.id)) {
      updatedPeople.push(finalPersonData);
    }
    localStorage.setItem('stoikPeople', JSON.stringify(updatedPeople));

    onComplete({ userData, personData: finalPersonData, selectedSuggestion: formattedSuggestion });
  }

  const CurrentStep = steps[step]?.component;

  if (!CurrentStep) {
    console.error('No valid step found for step:', step);
    return <div>Error: Invalid step in onboarding process.</div>;
  }

  console.log('Rendering step:', step, 'Component:', CurrentStep.name);

  return (
    <div className="onboarding-flow w-full h-full flex flex-col">
      <div className="flex-grow overflow-auto bg-white p-4 rounded-lg shadow-md">
        <motion.div
          key={step}
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -50 }}
          transition={{ duration: 0.3 }}
          className="h-full"
        >
          <CurrentStep {...steps[step].props} />
        </motion.div>
      </div>
    </div>
  );
};

const RecommendationsStep = ({ userData, personData, onComplete }) => {
  const suggestions = generateMomentSuggestions([personData], new Date());

  return (
    <div className="flex items-center justify-center min-h-screen bg-gradient-to-b from-pink-100 to-purple-100 p-4">
      <div className="bg-white rounded-3xl shadow-xl w-[600px] p-6">
        <h2 className="text-2xl font-bold mb-4">Recommended Moments</h2>
        <p className="mb-6">Based on what you've told us about {personData.name}, here are some moment suggestions:</p>
        
        <OnboardingRecommendations 
          suggestions={suggestions}
          onComplete={onComplete}
        />

        <button
          onClick={() => onComplete(null)}
          className="w-full bg-blue-500 text-white px-4 py-2 rounded-full hover:bg-blue-600 transition duration-200 mt-6"
        >
          Skip for now
        </button>
      </div>
    </div>
  );
};

export default OnboardingFlow;