import { isPast, parseISO, startOfDay } from 'date-fns';
import { v4 as uuidv4 } from 'uuid';

export const convertMomentToPastEvent = (moment, personId) => {
  return {
    ...moment,
    id: moment.id || uuidv4(), // Ensure the moment has an ID
    personId: personId, // Include the person ID
    isPastEvent: true,
    momentType: moment.momentType, // Keep the existing momentType
    activity: moment.activity || '',
    notes: moment.notes || `Auto-converted from moment on ${moment.date}`,
    location: moment.location || '',
    restaurant: moment.restaurant || '',
    gift: moment.gift || '',
    cost: moment.cost || '',
    rating: moment.rating || 0,
    tags: [...(moment.tags || []), 'Auto-converted'],
    participants: [personId], // Include the person as a participant
    photos: moment.photos || []
  };
};

export const updatePeopleWithPastMoments = (people) => {
  const today = startOfDay(new Date());
  console.log('Today (midnight):', {
    date: today.toISOString(),
    timestamp: today.getTime()
  });

  return people.map(person => {
    const pastEvents = (person.moments || [])
      .filter(moment => {
        const momentDate = startOfDay(parseISO(moment.date + 'T00:00:00'));
        const isPast = momentDate < today;
        
        console.log('Date comparison:', {
          momentId: moment.id,
          title: moment.title || 'Untitled',
          originalDate: moment.date,
          momentDate: {
            date: momentDate.toISOString(),
            localDate: momentDate.toLocaleDateString(),
            timestamp: momentDate.getTime()
          },
          today: {
            date: today.toISOString(),
            localDate: today.toLocaleDateString(),
            timestamp: today.getTime()
          },
          isPast,
          difference: momentDate.getTime() - today.getTime(),
          alreadyPastEvent: moment.isPastEvent
        });

        return isPast && !moment.isPastEvent;
      })
      .map(moment => convertMomentToPastEvent(moment, person.id));

    const updatedMoments = person.moments.filter(moment => {
      const momentDate = startOfDay(parseISO(moment.date + 'T00:00:00'));
      return momentDate >= today || moment.isPastEvent;
    });

    if (pastEvents.length > 0) {
      console.log('Moving to past events:', {
        personName: person.name,
        pastEvents: pastEvents.map(e => ({
          id: e.id,
          date: e.date,
          title: e.title
        }))
      });
    }

    return {
      ...person,
      moments: updatedMoments,
      pastEvents: [...(person.pastEvents || []), ...pastEvents]
    };
  });
};
