import React from 'react';
import { Camera, User } from 'lucide-react';

export const PersonDetailsTabs = ({ currentTab, setCurrentTab }) => {
  const tabs = [
    { id: 'main', label: 'Main' },
    { id: 'interests', label: 'Interests' },
    { id: 'whatTheyLike', label: 'Likes' },
    { id: 'moments', label: 'Moments' }
  ];

  const handleTabClick = (tabId) => {
    console.log('Tab clicked:', tabId);
    console.log('Current tab was:', currentTab);
    setCurrentTab(tabId);
  };

  return (
    <div className="mb-6">
      <div className="flex border-b">
        {tabs.map((tab) => (
          <button
            key={tab.id}
            onClick={() => handleTabClick(tab.id)}
            className={`
              py-2 px-4 
              ${currentTab === tab.id 
                ? 'border-b-2 border-blue-500 text-blue-500 font-medium' 
                : 'text-gray-500 hover:text-gray-700'
              }
              transition-colors
              relative
              group
            `}
          >
            {tab.label}
            {/* Active Tab Indicator */}
            {currentTab === tab.id && (
              <div className="absolute bottom-0 left-0 w-full h-0.5 bg-blue-500" />
            )}
            {/* Hover Indicator */}
            <div className={`
              absolute bottom-0 left-0 w-full h-0.5 
              ${currentTab === tab.id ? 'bg-blue-500' : 'bg-gray-300'} 
              transform scale-x-0 group-hover:scale-x-100 
              transition-transform origin-left
              ${currentTab === tab.id ? 'opacity-0' : 'opacity-100'}
            `} />
          </button>
        ))}
      </div>
    </div>
  );
};

// Shared UI Components
export const FormField = ({ label, children, tooltip }) => (
  <div className="mb-4">
    {label && (
      <label className="block text-sm font-medium text-gray-700 mb-2">
        {label}
        {tooltip && <Tooltip text={tooltip} />}
      </label>
    )}
    {children}
  </div>
);

export const Tooltip = ({ text }) => (
  <div className="group relative inline-block ml-2">
    <div className="cursor-help text-gray-400">
      <svg 
        xmlns="http://www.w3.org/2000/svg" 
        width="16" 
        height="16" 
        viewBox="0 0 24 24" 
        fill="none" 
        stroke="currentColor" 
        strokeWidth="2" 
        strokeLinecap="round" 
        strokeLinejoin="round"
      >
        <circle cx="12" cy="12" r="10"></circle>
        <line x1="12" y1="16" x2="12" y2="12"></line>
        <line x1="12" y1="8" x2="12.01" y2="8"></line>
      </svg>
    </div>
    <div className="
      opacity-0 group-hover:opacity-100
      transition-opacity
      absolute z-10
      bottom-full left-1/2 transform -translate-x-1/2 -translate-y-2
      px-3 py-2
      bg-gray-800 text-white text-xs rounded
      w-48
      pointer-events-none
    ">
      {text}
      {/* Arrow */}
      <div className="
        absolute bottom-0 left-1/2 transform -translate-x-1/2 translate-y-full
        border-4 border-transparent border-t-gray-800
      " />
    </div>
  </div>
);

export const Input = React.forwardRef(({ className = '', error, ...props }, ref) => (
  <input
    ref={ref}
    className={`
      w-full px-4 py-3 
      rounded-xl border border-gray-200 
      focus:border-blue-500 focus:ring-2 focus:ring-blue-200 
      transition-all outline-none
      ${error ? 'border-red-500 focus:border-red-500 focus:ring-red-200' : ''}
      ${className}
    `}
    {...props}
  />
));

export const Select = React.forwardRef(({ className = '', error, children, ...props }, ref) => (
  <select
    ref={ref}
    className={`
      w-full px-4 py-3 
      rounded-xl border border-gray-200 
      focus:border-blue-500 focus:ring-2 focus:ring-blue-200 
      transition-all outline-none
      appearance-none
      bg-white
      ${error ? 'border-red-500 focus:border-red-500 focus:ring-red-200' : ''}
      ${className}
    `}
    {...props}
  >
    {children}
  </select>
));

export const Checkbox = React.forwardRef(({ label, className = '', ...props }, ref) => (
  <label className="flex items-center space-x-2">
    <input
      type="checkbox"
      ref={ref}
      className={`
        rounded border-gray-300 
        text-blue-500 
        focus:ring-blue-200
        ${className}
      `}
      {...props}
    />
    <span className="text-sm text-gray-700">{label}</span>
  </label>
));

export const Button = React.forwardRef(({ 
  children, 
  variant = 'primary', 
  className = '',
  ...props 
}, ref) => {
  const baseStyles = "px-4 py-2 rounded-xl font-medium transition-colors flex items-center justify-center";
  const variants = {
    primary: "bg-blue-500 hover:bg-blue-600 text-white",
    secondary: "bg-gray-200 hover:bg-gray-300 text-gray-800",
    danger: "bg-red-500 hover:bg-red-600 text-white",
  };

  return (
    <button
      ref={ref}
      className={`${baseStyles} ${variants[variant]} ${className}`}
      {...props}
    >
      {children}
    </button>
  );
});

export const ErrorMessage = ({ children }) => (
  <p className="text-sm text-red-500 mt-1">{children}</p>
);

export const FormSection = ({ title, children, className = '' }) => (
  <div className={`space-y-4 ${className}`}>
    {title && (
      <h3 className="text-lg font-semibold text-gray-900">{title}</h3>
    )}
    {children}
  </div>
);


// Export all components
export default {
  PersonDetailsTabs,
  FormField,
  Tooltip,
  Input,
  Select,
  Checkbox,
  Button,
  ErrorMessage,
  FormSection
};
