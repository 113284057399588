import React, { useState } from 'react';
import { User, Mail, MapPin } from 'lucide-react';
import { trackEvent, setUserProperties } from './utils/analytics';

const InputField = ({ icon: Icon, type, placeholder, value, onChange, error }) => (
  <div className="mb-4">
    <div className="flex items-center border-b border-gray-300 py-2">
      <Icon className="text-gray-400 mr-3" size={20} />
      <input
        type={type}
        className="appearance-none bg-transparent border-none w-full text-gray-700 mr-3 py-1 px-2 leading-tight focus:outline-none"
        placeholder={placeholder}
        value={value}
        onChange={onChange}
      />
    </div>
    {error && <p className="text-red-500 text-xs italic mt-1">{error}</p>}
  </div>
);

const UserOnboardingScreen = ({ onSubmit }) => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [zipCode, setZipCode] = useState('');
  const [analyticsConsent, setAnalyticsConsent] = useState(false);
  const [errors, setErrors] = useState({});

  const validateForm = () => {
    const newErrors = {};
    if (!name.trim()) newErrors.name = 'Name is required';
    if (!email.trim()) newErrors.email = 'Email is required';
    else if (!/\S+@\S+\.\S+/.test(email)) newErrors.email = 'Email is invalid';
    if (!zipCode.trim()) newErrors.zipCode = 'Zip code is required';
    else if (!/^\d{5}(-\d{4})?$/.test(zipCode)) newErrors.zipCode = 'Zip code is invalid';
    if (!analyticsConsent) newErrors.consent = 'Please accept the analytics consent to continue';
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      if (analyticsConsent) {
        setUserProperties({
          email: email.toLowerCase(),
          name: name,
          zipCode: zipCode
        });

        trackEvent('waitlist_conversion', {
          email: email.toLowerCase(),
          source: 'onboarding'
        });
      }

      onSubmit({ 
        name, 
        email, 
        zipCode,
        analyticsConsent // Pass consent to parent component
      });
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gradient-to-b from-pink-100 to-purple-100 p-4">
      <div className="bg-white rounded-3xl shadow-xl w-[360px] p-6">
        <h1 className="text-2xl font-bold mb-6 text-center">Tell us about yourself</h1>
        
        <form onSubmit={handleSubmit}>
          <InputField
            icon={User}
            type="text"
            placeholder="Your Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            error={errors.name}
          />
          
          <InputField
            icon={Mail}
            type="email"
            placeholder="Email Address"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            error={errors.email}
          />
          
          <InputField
            icon={MapPin}
            type="text"
            placeholder="Zip Code"
            value={zipCode}
            onChange={(e) => setZipCode(e.target.value)}
            error={errors.zipCode}
          />

          <div className="mb-4">
            <label className="flex items-start space-x-2 cursor-pointer">
              <input
                type="checkbox"
                checked={analyticsConsent}
                onChange={(e) => setAnalyticsConsent(e.target.checked)}
                className="mt-1"
              />
              <span className="text-sm text-gray-600">
                I agree to allow humanBetter to collect my email address and usage data to improve the service. 
                You can opt out at any time in settings. View our{' '}
                <a 
                  href="https://www.tryhumanbetter.com/privacy" 
                  target="_blank" 
                  className="text-blue-600 hover:text-blue-800 underline"
                >
                  Privacy Policy
                </a>
              </span>
            </label>
          </div>

          <button
            type="submit"
            className="w-full bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded-full transition duration-300 mt-6"
          >
            Get Started
          </button>
        </form>
      </div>
    </div>
  );
};

export default UserOnboardingScreen;
