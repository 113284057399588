import React from 'react';
import { HelpCircle, User, Camera, Calendar, Clock, Star } from 'lucide-react';
import { relationships, interests, brands, favoriteFoods } from './data';
import { format, parseISO } from 'date-fns';

export const PersonDetailsForm = ({
  currentTab,
  personDetails,
  handleInputChange,
  handleLoveLanguageChange,
  handleImportanceChange,
  handleMultiSelectChange
}) => {
  const renderTooltip = (text) => (
    <div className="group relative inline-block">
      <HelpCircle size={16} className="text-gray-400 cursor-help" />
      <div className="absolute z-10 hidden group-hover:block bg-gray-800 text-white text-xs rounded p-2 -mt-2 ml-6">
        {text}
      </div>
    </div>
  );

  const renderMainForm = () => (
    <div className="space-y-6">
      <div className="mb-6 border-b pb-6">
        <div className="flex flex-col items-center">
          <div className="mb-3">
            {personDetails.image ? (
              <img 
                src={personDetails.image} 
                alt={personDetails.name}
                className="w-24 h-24 rounded-full object-cover border-2 border-gray-200"
              />
            ) : (
              <div className="w-24 h-24 rounded-full bg-gray-100 flex items-center justify-center border-2 border-gray-200">
                <User className="w-12 h-12 text-gray-400" />
              </div>
            )}
          </div>
          
          <label className="inline-flex items-center px-4 py-2 bg-gray-100 text-gray-700 rounded-full cursor-pointer hover:bg-gray-200 transition-colors">
            <Camera className="w-4 h-4 mr-2" />
            <span className="text-sm">Upload Photo</span>
            <input
              type="file"
              accept="image/*"
              className="hidden"
              onChange={(e) => {
                const file = e.target.files[0];
                const reader = new FileReader();
                
                reader.onloadend = () => {
                  handleInputChange('image', reader.result);
                };
                
                if (file) {
                  reader.readAsDataURL(file);
                }
              }}
            />
          </label>
        </div>
      </div>

      <div>
        <input
          type="text"
          value={personDetails.name || ''}
          onChange={(e) => handleInputChange('name', e.target.value)}
          placeholder="Name"
          className="w-full px-4 py-3 rounded-xl border border-gray-200 focus:border-blue-500 focus:ring-2 focus:ring-blue-200 transition-all outline-none"
        />
      </div>

      <div>
        <select
          value={personDetails.relationship || ''}
          onChange={(e) => handleInputChange('relationship', e.target.value)}
          className="w-full px-4 py-3 rounded-xl border border-gray-200 focus:border-blue-500 focus:ring-2 focus:ring-blue-200 transition-all outline-none"
        >
          <option value="">Select relationship</option>
          {relationships.map((rel) => (
            <option key={rel} value={rel}>{rel}</option>
          ))}
        </select>
      </div>

      {(personDetails.relationship === 'husband' || personDetails.relationship === 'wife') && (
        <div>
          <input
            type="number"
            value={personDetails.marriageYear || ''}
            onChange={(e) => {
              const year = e.target.value;
              if (year === '' || (year.length <= 4 && /^\d+$/.test(year))) {
                handleInputChange('marriageYear', year);
              }
            }}
            min="1900"
            max={new Date().getFullYear()}
            placeholder="Year you got married (YYYY)"
            className="w-full px-4 py-3 rounded-xl border border-gray-200 focus:border-blue-500 focus:ring-2 focus:ring-blue-200 transition-all outline-none"
          />
        </div>
      )}

      {(personDetails.relationship === 'son' || personDetails.relationship === 'daughter') && (
        <div>
          <input
            type="number"
            value={personDetails.birthYear || ''}
            onChange={(e) => handleInputChange('birthYear', e.target.value)}
            placeholder="Year of Birth"
            className="w-full px-4 py-3 rounded-xl border border-gray-200 focus:border-blue-500 focus:ring-2 focus:ring-blue-200 transition-all outline-none"
          />
        </div>
      )}

      <div>
        <input
          type="text"
          value={personDetails.zipCode || ''}
          onChange={(e) => handleInputChange('zipCode', e.target.value)}
          placeholder="Zip Code"
          className="w-full px-4 py-3 rounded-xl border border-gray-200 focus:border-blue-500 focus:ring-2 focus:ring-blue-200 transition-all outline-none"
        />
      </div>

      {(personDetails.relationship === 'husband' || personDetails.relationship === 'wife') && (
        <div>
          <input
            type="text"
            value={personDetails.favoriteFlower || ''}
            onChange={(e) => handleInputChange('favoriteFlower', e.target.value)}
            placeholder="Favorite Flower"
            className="w-full px-4 py-3 rounded-xl border border-gray-200 focus:border-blue-500 focus:ring-2 focus:ring-blue-200 transition-all outline-none"
          />
        </div>
      )}
    </div>
  );

  const renderInterestsTab = () => (
    <div className="space-y-6">
      <h3 className="text-lg font-semibold">Interests</h3>
      <div className="grid grid-cols-2 gap-2 max-h-40 overflow-y-auto rounded-xl border border-gray-200 p-4">
        {interests.map((interest) => (
          <label key={interest} className="flex items-center space-x-2">
            <input
              type="checkbox"
              checked={personDetails.interests?.includes(interest) || false}
              onChange={() => handleMultiSelectChange('interests', interest)}
              className="rounded border-gray-300 text-blue-500 focus:ring-blue-200"
            />
            <span className="text-sm">{interest}</span>
          </label>
        ))}
      </div>

      <h3 className="text-lg font-semibold">Favorite Foods</h3>
      <div className="grid grid-cols-2 gap-2 max-h-40 overflow-y-auto rounded-xl border border-gray-200 p-4">
        {favoriteFoods.map((food) => (
          <label key={food} className="flex items-center space-x-2">
            <input
              type="checkbox"
              checked={personDetails.favoriteFoods?.includes(food) || false}
              onChange={() => handleMultiSelectChange('favoriteFoods', food)}
              className="rounded border-gray-300 text-blue-500 focus:ring-blue-200"
            />
            <span className="text-sm">{food}</span>
          </label>
        ))}
      </div>

      <h3 className="text-lg font-semibold">Favorite Brands</h3>
      <select
        multiple
        value={personDetails.favoriteBrands || []}
        onChange={(e) => handleInputChange('favoriteBrands', 
          Array.from(e.target.selectedOptions, option => option.value)
        )}
        className="w-full px-4 py-3 rounded-xl border border-gray-200 focus:border-blue-500 focus:ring-2 focus:ring-blue-200 h-40"
      >
        {brands.map((brand) => (
          <option key={brand} value={brand}>{brand}</option>
        ))}
      </select>
    </div>
  );

  const renderWhatTheyLikeTab = () => (
    <div className="space-y-6">
      <div>
        <label className="block text-lg font-semibold">
        What makes them feel most appreciated? {renderTooltip("What action seems to make them feel most appreciated?")}
        </label>
        <select
          value={personDetails.loveLanguage?.happinessAction || ''}
          onChange={(e) => handleLoveLanguageChange('happinessAction', e.target.value)}
          className="w-full px-4 py-3 rounded-xl border border-gray-200 focus:border-blue-500 focus:ring-2 focus:ring-blue-200"
        >
          <option value="">Select an option</option>
          <option value="words">Encouraging words</option>
          <option value="acts">Getting help</option>
          <option value="gifts">Receiving gifts</option>
          <option value="time">Spending time</option>
          <option value="touch">Physical feedback (hugs, pat on back)</option>
        </select>
      </div>

      <div>
        <label className="block text-lg font-semibold">
        How do they show they care? {renderTooltip("How do they typically express care or concern for others?")}
        </label>
        {["words", "acts", "gifts", "time", "touch"].map((option) => (
          <label key={option} className="flex items-center space-x-2 mb-2">
            <input
              type="checkbox"
              checked={personDetails.loveLanguage?.showingLove?.includes(option) || false}
              onChange={() => {
                const current = personDetails.loveLanguage?.showingLove || [];
                handleLoveLanguageChange('showingLove', 
                  current.includes(option) 
                    ? current.filter(item => item !== option)
                    : [...current, option]
                );
              }}
              className="rounded border-gray-300 text-blue-500 focus:ring-blue-200"
            />
            <span className="text-sm">
              {option === "words" && "Encouraging words"}
              {option === "acts" && "Helping others"}
              {option === "gifts" && "Giving gifts"}
              {option === "time" && "Spending time"}
              {option === "touch" && "Physical feedback (hugs, pat on back)"}
            </span>
          </label>
        ))}
      </div>

      <div>
        <label className="block text-lg font-semibold">
          How meaningful are these to them? {renderTooltip("Rate the importance of each type of action")}
        </label>
        {[
          { key: "words", label: "Encouraging words" },
          { key: "time", label: "Spending time" },
          { key: "help", label: "Getting help" },
          { key: "touch", label: "Physical feedback (hugs, pat on back)" },
          { key: "gifts", label: "Receiving gifts" }
        ].map(({ key, label }) => (
          <div key={key} className="mb-4">
            <label className="block text-sm text-gray-600 mb-1">{label}</label>
            <input
              type="range"
              min="1"
              max="5"
              value={personDetails.loveLanguage?.importance?.[key] || 1}
              onChange={(e) => handleImportanceChange(key, parseInt(e.target.value))}
              className="w-full"
            />
            <div className="flex justify-between text-xs text-gray-500">
              <span>Not Important</span>
              <span>Very Important</span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );

  const renderMomentsTab = () => {
    const upcomingMoments = personDetails.moments || [];
    const pastMoments = personDetails.pastEvents || [];

    const handleRatingChange = (momentId, rating) => {
      const updatedPastEvents = pastMoments.map(moment => 
        moment.id === momentId ? { ...moment, rating } : moment
      );
      handleInputChange('pastEvents', updatedPastEvents);
    };

    const StarRating = ({ rating, onRatingChange }) => {
      return (
        <div className="flex space-x-1">
          {[1, 2, 3, 4, 5].map((star) => (
            <button
              key={star}
              onClick={() => onRatingChange(star)}
              className="focus:outline-none"
            >
              <Star
                size={16}
                className={`${
                  star <= (rating || 0)
                    ? 'text-yellow-400 fill-current'
                    : 'text-gray-300'
                } hover:text-yellow-400 transition-colors`}
              />
            </button>
          ))}
        </div>
      );
    };

    return (
      <div className="space-y-6">
        {/* Upcoming Moments Section */}
        <div>
          <h3 className="text-lg font-semibold mb-4">Upcoming Moments</h3>
          {upcomingMoments.length > 0 ? (
            <div className="space-y-3">
              {upcomingMoments.map(moment => (
                <div 
                  key={moment.id}
                  className="bg-white rounded-xl p-4 border border-gray-200 hover:border-indigo-200 transition-colors"
                >
                  <div className="flex items-start justify-between">
                    <div>
                      <h4 className="font-medium">{moment.title || moment.momentType}</h4>
                      <div className="flex items-center text-sm text-gray-500 mt-1">
                        <Calendar size={14} className="mr-1" />
                        {format(parseISO(moment.date), 'MMM d, yyyy')}
                      </div>
                      {moment.notes && (
                        <p className="text-sm text-gray-600 mt-2">{moment.notes}</p>
                      )}
                    </div>
                    <span className="px-2 py-1 bg-indigo-100 text-indigo-700 rounded-full text-xs">
                      {moment.effortLevel}
                    </span>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <p className="text-gray-500 text-sm">No upcoming moments planned</p>
          )}
        </div>

        {/* Past Moments Section */}
        <div>
          <h3 className="text-lg font-semibold mb-4">Past Moments</h3>
          {pastMoments.length > 0 ? (
            <div className="space-y-3">
              {pastMoments.map(moment => (
                <div 
                  key={moment.id}
                  className="bg-gray-50 rounded-xl p-4 border border-gray-200"
                >
                  <div className="flex items-start justify-between">
                    <div>
                      <h4 className="font-medium text-gray-700">
                        {moment.title || moment.momentType}
                      </h4>
                      <div className="flex items-center text-sm text-gray-500 mt-1">
                        <Clock size={14} className="mr-1" />
                        {format(parseISO(moment.date), 'MMM d, yyyy')}
                      </div>
                      {moment.notes && (
                        <p className="text-sm text-gray-600 mt-2">{moment.notes}</p>
                      )}
                      <div className="mt-3">
                        <div className="flex items-center space-x-2">
                          <span className="text-sm text-gray-600">Rate this moment:</span>
                          <StarRating
                            rating={moment.rating}
                            onRatingChange={(rating) => handleRatingChange(moment.id, rating)}
                          />
                        </div>
                      </div>
                    </div>
                    <span className="px-2 py-1 bg-gray-200 text-gray-600 rounded-full text-xs">
                      {moment.effortLevel}
                    </span>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <p className="text-gray-500 text-sm">No past moments recorded</p>
          )}
        </div>
      </div>
    );
  };

  // Return the appropriate form based on current tab
  switch (currentTab) {
    case 'interests':
      return renderInterestsTab();
    case 'whatTheyLike':
      return renderWhatTheyLikeTab();
    case 'moments':
      return renderMomentsTab();
    default:
      return renderMainForm();
  }
};

export default PersonDetailsForm;
