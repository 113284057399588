import React, { useState, useEffect } from 'react';
import { X, Camera, User, ChevronRight } from 'lucide-react';
import { PersonDetailsForm } from './PersonDetailsForm';
import { PersonDetailsTabs } from './PersonDetailsTabs';

const PersonDetailsModal = ({ 
  isOpen, 
  onClose, 
  person, 
  onUpdatePerson, 
  onDeletePerson,
  onStepComplete,
  isOnboarding = false,
  activeTab = 'main'
}) => {
  const [personDetails, setPersonDetails] = useState({
    id: '',
    name: '',
    relationship: '',
    marriageYear: '',
    birthYear: '',
    zipCode: '',
    image: '',
    favoriteFoods: [],
    interests: [],
    favoriteBrands: [],
    favoriteFlower: '',
    moments: [],
    pastEvents: [],
    loveLanguage: {
      happinessAction: '',
      showingLove: [],
      comfortAction: '',
      importance: {
        words: 1,
        time: 1,
        help: 1,
        touch: 1,
        gifts: 1
      }
    }
  });
  const [currentTab, setCurrentTab] = useState(activeTab);

  useEffect(() => {
    if (person) {
      setPersonDetails(prev => ({
        ...prev,
        ...person,
        loveLanguage: {
          ...prev.loveLanguage,
          ...(person.loveLanguage || {}),
          importance: {
            ...prev.loveLanguage.importance,
            ...(person.loveLanguage?.importance || {})
          }
        }
      }));
    }
  }, [person]);

  const handleInputChange = (field, value) => {
    setPersonDetails(prev => ({
      ...prev,
      [field]: value
    }));
  };

  const handleLoveLanguageChange = (field, value) => {
    setPersonDetails(prev => ({
      ...prev,
      loveLanguage: {
        ...prev.loveLanguage,
        [field]: value
      }
    }));
  };

  const handleImportanceChange = (field, value) => {
    setPersonDetails(prev => ({
      ...prev,
      loveLanguage: {
        ...prev.loveLanguage,
        importance: {
          ...prev.loveLanguage.importance,
          [field]: value
        }
      }
    }));
  };

  const handleMultiSelectChange = (field, item) => {
    const updatedItems = personDetails[field]?.includes(item)
      ? personDetails[field].filter(i => i !== item)
      : [...(personDetails[field] || []), item];
    handleInputChange(field, updatedItems);
  };

  const handleSave = () => {
    if (isOnboarding) {
      onStepComplete(personDetails);
    } else {
      onUpdatePerson(personDetails);
      onClose();
    }
  };

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    
    reader.onloadend = () => {
      handleInputChange('image', reader.result);
      console.log('Image uploaded and converted to base64');
    };
    
    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const containerClass = isOnboarding
    ? "min-h-screen bg-gradient-to-b from-pink-100 to-purple-100 p-4 flex items-center justify-center"
    : "fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50";

  const contentClass = isOnboarding
    ? "bg-white rounded-3xl shadow-xl w-[460px] p-8"
    : "bg-white rounded-3xl shadow-xl w-[460px] p-8 max-h-[90vh] overflow-y-auto relative";

  const handleTabChange = (tab) => {
    console.log('Tab changed to:', tab);
    setCurrentTab(tab);
  };

  if (!isOpen && !isOnboarding) return null;

  return (
    <div className={containerClass}>
      <div className={contentClass}>
        {!isOnboarding && (
          <button 
            onClick={onClose}
            className="absolute top-4 right-4 text-gray-500 hover:text-gray-700"
          >
            <X size={24} />
          </button>
        )}

        <h1 className="text-2xl font-bold text-center mb-2">
          {isOnboarding ? "Let's get to know who you want to connect with better" : "Person Details"}
        </h1>
        
        <p className="text-gray-600 text-center text-sm mb-8">
          {isOnboarding 
            ? "This should not take more than a few minutes" 
            : "Update information about your person"}
        </p>

        {!isOnboarding && (
          <PersonDetailsTabs 
            currentTab={currentTab}
            setCurrentTab={handleTabChange}
          />
        )}

        <PersonDetailsForm
          currentTab={currentTab}
          personDetails={personDetails}
          handleInputChange={handleInputChange}
          handleLoveLanguageChange={handleLoveLanguageChange}
          handleImportanceChange={handleImportanceChange}
          handleMultiSelectChange={handleMultiSelectChange}
        />

        <div className="mt-6">
          <button
            onClick={handleSave}
            className="w-full bg-blue-500 hover:bg-blue-600 text-white font-medium py-3 px-4 rounded-xl transition-colors flex items-center justify-center space-x-2"
          >
            <span>{isOnboarding ? "Continue" : "Save Changes"}</span>
            <ChevronRight size={20} />
          </button>

          {!isOnboarding && (
            <button
              onClick={() => onDeletePerson(personDetails.id)}
              className="w-full mt-4 bg-red-500 hover:bg-red-600 text-white font-medium py-3 px-4 rounded-xl transition-colors"
            >
              Delete Person
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default PersonDetailsModal;