// utils/relationshipHealthUtils.js

import { differenceInDays, parseISO } from 'date-fns';

export const HEALTH_LEVELS = {
  NEEDS_ATTENTION: {
    min: 0,
    max: 3,
    name: 'Needs Attention',
    icon: '💝',
    description: 'This relationship could use some focus',
    nextGoal: 'Plan a small moment this week'
  },
  MAINTAINING: {
    min: 3,
    max: 5,
    name: 'Maintaining',
    icon: '🌱',
    description: 'Keeping the connection steady',
    nextGoal: 'Try adding 2-3 moments in the next week'
  },
  NURTURING: {
    min: 5,
    max: 7,
    name: 'Nurturing',
    icon: '🌿',
    description: 'Actively growing the relationship',
    nextGoal: 'Keep up the regular connections'
  },
  THRIVING: {
    min: 7,
    max: 9,
    name: 'Thriving',
    icon: '🌺',
    description: 'Strong, active relationship',
    nextGoal: 'Maintain your great momentum'
  },
  FLOURISHING: {
    min: 9,
    max: 10,
    name: 'Flourishing',
    icon: '✨',
    description: 'Exceptional relationship health',
    nextGoal: "You're doing amazing! Keep it up!"
  }
};

export const getHealthLevel = (score) => {
  return Object.values(HEALTH_LEVELS).find(
    level => score > level.min && score <= level.max
  ) || HEALTH_LEVELS.NEEDS_ATTENTION; // Fallback to prevent undefined
};

export const calculateHealthScore = (person) => {
  const momentFrequency = person.moments?.length || 0;
  const recentMoments = person.moments?.filter(moment => 
    differenceInDays(new Date(), parseISO(moment.date)) <= 30
  ).length || 0;
  
  const pastRatings = (person.pastEvents || [])
    .filter(event => event.rating)
    .map(event => event.rating);
  
  const averageRating = pastRatings.length > 0
    ? pastRatings.reduce((sum, rating) => sum + rating, 0) / pastRatings.length
    : 0;

  const ratingBonus = averageRating * 0.5;
  
  return Math.min(10, Math.max(1, (momentFrequency + recentMoments) / 3 + ratingBonus));
};

export const updatePersonWithRelationshipHealth = (person) => {
  const currentHealth = calculateHealthScore(person);
  
  return {
    ...person,
    previousHealthScore: person.currentHealthScore || currentHealth,
    currentHealthScore: currentHealth,
    lastHealthUpdate: new Date().toISOString()
  };
};