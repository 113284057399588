import React, { useState, useEffect, useCallback } from 'react';
import { format, parseISO, isSameDay, isValid } from 'date-fns';
import { X, RefreshCw, Loader } from 'lucide-react';

const MomentDetailsModal = ({ isOpen, onClose, date, moments, people, onUpdateMoment, onDeleteMoment }) => {
  const [activeTabId, setActiveTabId] = useState(null);
  const [localMoments, setLocalMoments] = useState([]);
  const [isLoading, setIsLoading] = useState({});
  const [budgets, setBudgets] = useState({});
  
  const getBudgetSymbol = (budgetValue) => {
    switch (budgetValue) {
      case 1: return '$';
      case 2: return '$$';
      case 3: return '$$$';
      case 4: return '$$$$';
      default: return '$';
    }
  };

  const handleInputChange = useCallback((momentId, field, value) => {
    setLocalMoments(prevMoments => prevMoments.map(moment => 
      moment.id === momentId ? { ...moment, [field]: value } : moment
    ));
  }, []);

  const getSuggestion = useCallback(async (moment, type) => {
    console.log(`Getting suggestion for ${type}`);
  }, []);

  useEffect(() => {
    if (moments && moments.length > 0) {
      setLocalMoments(moments);
      setActiveTabId(moments[0].id);
    }
  }, [moments]);

  if (!isOpen || !moments || moments.length === 0) return null;

  const renderMomentSection = (moment, type) => (
    <div key={`${moment.id}-${type}`} className="mt-4">
      <div className="flex justify-between items-center">
        <h4 className="text-lg font-semibold capitalize">{type}</h4>
        <button
          onClick={() => getSuggestion(moment, type)}
          disabled={isLoading[moment.id]?.[type]}
          className="px-4 py-2 text-sm bg-blue-50 text-blue-600 rounded-md hover:bg-blue-100"
        >
          {isLoading[moment.id]?.[type] ? (
            <>
              <Loader className="inline-block w-4 h-4 mr-2 animate-spin" />
              <span>Getting suggestion...</span>
            </>
          ) : (
            <>
              <RefreshCw className="inline-block w-4 h-4 mr-2" />
              <span>Get suggestion</span>
            </>
          )}
        </button>
      </div>

      <div className="mt-2">
        <textarea
          value={moment[type] || ''}
          onChange={(e) => handleInputChange(moment.id, type, e.target.value)}
          className="w-full p-4 border border-gray-200 rounded-xl resize-none focus:border-blue-500 focus:ring-2 focus:ring-blue-200 transition-all outline-none"
          placeholder={`Enter ${type}`}
          rows={3}
        />
      </div>
    </div>
  );

  return (
    isOpen && (
      <div className="fixed inset-0 z-50 overflow-y-auto">
        <div className="flex items-center justify-center min-h-screen p-4">
          <div className="fixed inset-0 bg-black opacity-30" onClick={onClose} />
          
          <div className="relative bg-white rounded-xl shadow-xl max-w-3xl w-full">
            {/* Header */}
            <div className="flex justify-between items-center p-4 border-b">
              <h2 className="text-xl font-semibold">
                {format(new Date(date), 'MMMM d, yyyy')}
              </h2>
              <button onClick={onClose} className="text-gray-400 hover:text-gray-500">
                <X size={24} />
              </button>
            </div>

            {/* Tabs */}
            {moments.length > 1 && (
              <div className="border-b border-gray-200">
                <nav className="flex space-x-2 p-2">
                  {moments.map((moment) => {
                    const person = people.find(p => p.id === moment.personId);
                    const isActive = moment.id === activeTabId;
                    
                    return (
                      <button
                        key={moment.id}
                        onClick={() => setActiveTabId(moment.id)}
                        className={`px-4 py-2 rounded-t-lg text-sm font-medium transition-colors
                          ${isActive 
                            ? 'bg-white text-blue-600 border-b-2 border-blue-600' 
                            : 'text-gray-500 hover:text-gray-700 hover:bg-gray-50'
                          }`}
                      >
                        <div className="flex items-center space-x-2">
                          <span>{moment.momentType}</span>
                          <span className="text-xs text-gray-400">
                            - {person?.name || 'Unknown'}
                          </span>
                        </div>
                      </button>
                    );
                  })}
                </nav>
              </div>
            )}

            {/* Moment Sections */}
            <div className="flex-1 overflow-y-auto p-6">
              {localMoments
                .filter(moment => moment.id === activeTabId)
                .map(moment => (
                  <div key={moment.id}>
                    {['gift', 'activity', 'restaurant'].map(type => 
                      renderMomentSection(moment, type)
                    )}
                  </div>
                ))}
            </div>

            {/* Save Changes Button */}
            <div className="p-6 border-t border-gray-200">
              <button className="w-full bg-blue-600 text-white py-2 rounded-md hover:bg-blue-700">
                Save Changes
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  );
};

export default MomentDetailsModal;