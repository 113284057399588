import React, { useState, useEffect } from 'react';
import { format } from 'date-fns';
import { Calendar, RefreshCw } from 'lucide-react';
import { EFFORT_LEVELS, getRandomAction } from './data';

const EditMomentModal = ({ isOpen, onClose, moment, onUpdateMoment, people = [], momentTypes = [] }) => {
  const [date, setDate] = useState('');
  const [momentType, setMomentType] = useState('');
  const [person, setPerson] = useState('');
  const [notes, setNotes] = useState('');
  const [effortLevel, setEffortLevel] = useState('');
  const [suggestionText, setSuggestionText] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [previousSuggestions, setPreviousSuggestions] = useState({});

  useEffect(() => {
    if (moment) {
      setDate(moment.date || '');
      setMomentType(moment.momentType || '');
      setPerson(moment.personName || '');
      setNotes(moment.notes || '');
      setEffortLevel(moment.effortLevel || '');
    }
  }, [moment]);

  useEffect(() => {
    if (momentType && person && Array.isArray(people)) {
      const selectedPerson = people.find(p => p.name === person);
      if (selectedPerson?.loveLanguage?.importance) {
        const primaryLanguage = Object.entries(selectedPerson.loveLanguage.importance)
          .sort(([, a], [, b]) => b - a)[0][0];

        // Get suggestions for each effort level
        const suggestionsByEffort = {};
        Object.keys(EFFORT_LEVELS).forEach(level => {
          const suggestion = getRandomAction(primaryLanguage, level);
          if (suggestion) {
            suggestionsByEffort[level] = suggestion;
          }
        });

        setSuggestions(suggestionsByEffort);
        setPreviousSuggestions(prev => ({
          ...prev,
          ...Object.entries(suggestionsByEffort).reduce((acc, [level, suggestion]) => ({
            ...acc,
            [level]: [...(prev[level] || []), suggestion]
          }), {})
        }));
      }
    }
  }, [momentType, person, people]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const updatedMoment = {
      ...moment,
      date,
      momentType,
      notes,
      title: notes,
      effortLevel
    };

    onUpdateMoment(updatedMoment);
    onClose();
  };

  const handleRefreshSuggestions = () => {
    if (momentType && person && Array.isArray(people)) {
      const selectedPerson = people.find(p => p.name === person);
      if (selectedPerson?.loveLanguage?.importance) {
        const primaryLanguage = Object.entries(selectedPerson.loveLanguage.importance)
          .sort(([, a], [, b]) => b - a)[0][0];

        const suggestionsByEffort = {};
        Object.keys(EFFORT_LEVELS).forEach(level => {
          let newSuggestion;
          let attempts = 0;
          const maxAttempts = 10;

          do {
            newSuggestion = getRandomAction(primaryLanguage, level);
            attempts++;
          } while (
            newSuggestion && 
            previousSuggestions[level]?.includes(newSuggestion) && 
            attempts < maxAttempts
          );

          if (newSuggestion) {
            suggestionsByEffort[level] = newSuggestion;
          }
        });

        setSuggestions(suggestionsByEffort);
        setPreviousSuggestions(prev => ({
          ...prev,
          ...Object.entries(suggestionsByEffort).reduce((acc, [level, suggestion]) => ({
            ...acc,
            [level]: [...(prev[level] || []), suggestion]
          }), {})
        }));
      }
    }
  };

  if (!isOpen) return null;

  const inputClass = "w-full p-2 border border-gray-300 rounded-md text-sm text-gray-700 placeholder-gray-400";

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-3xl w-full max-w-md mx-4 overflow-hidden">
        <div className="bg-gray-100 p-4">
          <h3 className="text-xl font-semibold">Edit Moment</h3>
        </div>
        <form onSubmit={handleSubmit} className="p-6 space-y-4">
          {/* Human Selection (Read-only) */}
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1" htmlFor="person">
              Human
            </label>
            <input
              type="text"
              id="person"
              value={person}
              className={`${inputClass} bg-gray-50`}
              disabled
            />
          </div>

          {/* Date Selection */}
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1" htmlFor="date">
              Date
            </label>
            <div className="relative">
              <input
                type="date"
                id="date"
                value={date}
                onChange={(e) => setDate(e.target.value)}
                className={`${inputClass} pl-10`}
                required
              />
              <Calendar className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={20} />
            </div>
          </div>

          {/* Moment Type with Suggestions */}
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1" htmlFor="momentType">
              Moment Type
            </label>
            <select
              id="momentType"
              value={momentType}
              onChange={(e) => setMomentType(e.target.value)}
              className={inputClass}
              required
            >
              <option value="">Select a type of Moment</option>
              {momentTypes.map((type, index) => (
                <option key={index} value={type}>{type}</option>
              ))}
            </select>

            {/* Suggestions Section */}
            {Object.keys(suggestions).length > 0 && (
              <div className="mt-4 space-y-2">
                <div className="flex justify-between items-center">
                  <label className="block text-sm font-medium text-gray-700">
                    Suggested Activities
                  </label>
                  <button
                    type="button"
                    onClick={handleRefreshSuggestions}
                    className="text-gray-500 hover:text-gray-700 p-1 rounded-full hover:bg-gray-100 transition-colors"
                  >
                    <RefreshCw size={16} />
                  </button>
                </div>
                <div className="space-y-2">
                  {Object.entries(suggestions).map(([level, suggestion]) => (
                    <button
                      key={level}
                      type="button"
                      onClick={() => {
                        setNotes(suggestion);
                        setEffortLevel(level);
                      }}
                      className="w-full text-left p-3 rounded-md text-sm transition-colors"
                      style={{
                        backgroundColor: EFFORT_LEVELS[level].color,
                        opacity: 0.8,
                        hover: { opacity: 1 }
                      }}
                    >
                      <div className="flex justify-between items-center">
                        <span>{suggestion}</span>
                        <span className="text-xs">
                          {EFFORT_LEVELS[level].description}
                        </span>
                      </div>
                    </button>
                  ))}
                </div>
              </div>
            )}
          </div>

          {/* Notes */}
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1" htmlFor="notes">
              Activity
            </label>
            <textarea
              id="notes"
              value={notes}
              onChange={(e) => setNotes(e.target.value)}
              className={inputClass}
              rows="3"
              placeholder="Message"
            />
          </div>

          <input type="hidden" value={effortLevel} />

          {/* Action Buttons */}
          <button
            type="submit"
            className="w-full bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600 transition duration-200"
          >
            Save Changes
          </button>
          <button
            type="button"
            onClick={onClose}
            className="w-full bg-gray-200 text-gray-800 py-2 px-4 rounded-md hover:bg-gray-300 transition duration-200"
          >
            Cancel
          </button>
        </form>
      </div>
    </div>
  );
};

export default EditMomentModal; 