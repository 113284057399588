import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import { parseISO } from 'date-fns';
import { Clock, Gem, Mountain, Utensils, Heart, User } from 'lucide-react';
import EditMomentModal from './EditMomentModal';
import { EFFORT_LEVELS } from './data';

const getLoveLanguageEmoji = (loveLanguage) => {
  const emojis = {
    words: '💭',
    time: '⌛',
    gifts: '🎁',
    service: '🤝',
    touch: '💝',
    general: '❤️'
  };
  return emojis[loveLanguage?.toLowerCase()] || emojis.general;
};

const NextUpCard = ({ 
  moment, 
  onUpdateMoment, 
  momentTypes = [],
  people = [],
}) => {
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const effortLevel = moment.effortLevel || 'micro';
  const backgroundColor = EFFORT_LEVELS[effortLevel]?.color || EFFORT_LEVELS.micro.color;
  const loveLanguage = moment.momentType?.toLowerCase() || 'general';

  if (!moment) return null;

  return (
    <>
      <div className="flex-shrink-0 w-[298px]">
        <div className="bg-white rounded-3xl overflow-hidden mr-3 w-[268px] h-[390px] flex flex-col">
          {/* Colored Header Section - now using effort level color */}
          <div className="p-6" style={{ backgroundColor }}>
            <div className="flex justify-between items-start mb-4">
              <div className="w-10 h-10 rounded-full bg-white flex items-center justify-center">
                {/* Love Language Emoji */}
                <span className="text-xl">{getLoveLanguageEmoji(loveLanguage)}</span>
              </div>
              <div className="bg-white rounded-full px-3 py-1">
                <span className="text-xs text-gray-600">
                  {EFFORT_LEVELS[effortLevel].description}
                </span>
              </div>
            </div>
            <div className="flex space-x-4 mt-4">
              <Gem 
                size={24} 
                className={moment.gift ? "text-purple-600" : "text-gray-300"} 
              />
              <Mountain 
                size={24} 
                className={moment.activity ? "text-purple-600" : "text-gray-300"} 
              />
              <Utensils 
                size={24} 
                className={moment.restaurant ? "text-purple-600" : "text-gray-300"} 
              />
            </div>
          </div>

          {/* Content Section */}
          <div className="p-6 flex flex-col flex-grow">
            <h3 className="text-lg mb-2">
            {moment.title} 
            </h3>
            {moment.title && (
              <p className="text-gray-600 text-sm mb-4">with {moment.personName}</p>
            )}
            <div className="mt-auto flex justify-between items-end">
              <div>
                <p className="font-bold">
                  {moment.daysUntil === 0 ? (
                    <>
                      <span className="text-[32px] leading-none text-indigo-600">Today</span>
                    </>
                  ) : (
                    <>
                      <span className="text-[48px] leading-none">{moment.daysUntil}</span>
                      <span className="text-[16px] ml-2">Days till</span>
                    </>
                  )}
                </p>
                <button 
                  onClick={() => setIsEditModalOpen(true)}
                  className="mt-2 text-sm text-indigo-600 hover:text-indigo-800"
                >
                  View Details
                </button>
              </div>
              
              {/* Add person image */}
              <div className="w-16 h-16 rounded-full overflow-hidden border-2 border-white shadow-md">
                {people.find(p => p.id === moment.personId)?.image ? (
                  <img 
                    src={people.find(p => p.id === moment.personId)?.image}
                    alt={moment.personName}
                    className="w-full h-full object-cover"
                  />
                ) : (
                  <div className="w-full h-full bg-gray-100 flex items-center justify-center">
                    <User className="w-8 h-8 text-gray-400" />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {isEditModalOpen && 
        ReactDOM.createPortal(
          <EditMomentModal
            isOpen={isEditModalOpen}
            onClose={() => setIsEditModalOpen(false)}
            moment={moment}
            onUpdateMoment={onUpdateMoment}
            people={people}
            momentTypes={momentTypes}
          />,
          document.body
        )
      }
    </>
  );
};

export default NextUpCard;