import React from 'react';
import { format, parseISO } from 'date-fns';
import { Calendar, Gift, Heart, Star, Users } from 'lucide-react';
import { EFFORT_LEVELS } from '../data';

const getIconComponent = (type) => {
  switch (type?.toLowerCase()) {
    case 'gift':
      return <Gift size={16} />;
    case 'activity':
      return <Users size={16} />;
    case 'celebration':
      return <Star size={16} />;
    case 'care':
      return <Heart size={16} />;
    default:
      return <Calendar size={16} />;
  }
};

const OnboardingRecommendations = ({ suggestions, onComplete }) => {
  const limitedSuggestions = suggestions.slice(0, 6);

  return (
    <div className="space-y-6">
      <div className="grid grid-cols-2 gap-4">
        {limitedSuggestions.map((suggestion, index) => (
          <button
            key={index}
            onClick={() => onComplete(suggestion)}
            className="p-4 rounded-xl transition-all hover:scale-[1.02] hover:shadow-lg text-left group"
            style={{
              backgroundColor: EFFORT_LEVELS[suggestion.effortLevel]?.color || EFFORT_LEVELS.micro.color
            }}
          >
            <div className="flex items-start gap-3 mb-2">
              <div className="rounded-full bg-white/20 p-2 group-hover:bg-white/30 transition-colors">
                {getIconComponent(suggestion.type)}
              </div>
              <p className="text-sm font-medium line-clamp-2">
                {suggestion.suggestion}
              </p>
            </div>
            <div className="flex justify-between items-center text-xs mt-auto">
              <span>{EFFORT_LEVELS[suggestion.effortLevel].description}</span>
              <span>{format(parseISO(suggestion.date), 'MMM d')}</span>
            </div>
          </button>
        ))}
      </div>
    </div>
  );
};

export default OnboardingRecommendations; 