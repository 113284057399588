import { useState, useCallback, useEffect, useMemo } from 'react';
import { ChevronDown, Calendar, Users, Gift, Minus, Plus, Settings, MessageSquare, Mail } from 'lucide-react';
import { v4 as uuidv4 } from 'uuid';

// Components
import MomentsPage from './MomentsPage';
import PeoplePage from './PeoplePage';
import GiftsPage from './GiftsPage';
import AddMomentModal from './AddMomentModal';
import PersonDetailsModal from './PersonDetailsModal';
import OnboardingFlow from './OnboardingFlow';
import SettingsModal from './SettingsModal';
import NotificationBell from './components/notifications/NotificationBell';
import NotificationCenter from './components/notifications/NotificationCenter';
import RateMomentModal from './components/RateMomentModal';
import RatingModal from './components/RatingModal';
import TemplatePreview from './components/notifications/TemplatePreview';

// Data and Utils
import { initialPeople, momentTypes } from './data';
import { updatePeopleWithPastMoments } from './utils/momentHandling';
import { updatePersonWithRelationshipHealth } from './utils/relationshipHealthUtils';
import { trackEvent, setUserProperties } from './utils/analytics';
import './RiddleUI.css';

const APP_VERSION = 'v1.1.0'; // We'll track versions here

const App = () => {
  const [activeTab, setActiveTab] = useState('moments');
  const [currentDate, setCurrentDate] = useState(new Date());
  const [isAddMomentModalOpen, setIsAddMomentModalOpen] = useState(false);
  const [selectedPerson, setSelectedPerson] = useState(null);
  const [prefillMomentData, setPrefillMomentData] = useState(null);
  const [showOnboarding, setShowOnboarding] = useState(false);
  const [isSettingsModalOpen, setIsSettingsModalOpen] = useState(false);
  const [isFeedbackModalOpen, setIsFeedbackModalOpen] = useState(false);
  const [isNotificationCenterOpen, setIsNotificationCenterOpen] = useState(false);
  const [unreadNotifications, setUnreadNotifications] = useState([]);
  const [notifications, setNotifications] = useState([]);
  const [unratedMomentNotifications, setUnratedMomentNotifications] = useState([]);
  const [momentToRate, setMomentToRate] = useState(null);
  const [showTemplatePreview, setShowTemplatePreview] = useState(false);

  const [userData, setUserData] = useState(() => {
    const savedUserData = localStorage.getItem('userData');
    return savedUserData ? JSON.parse(savedUserData) : null;
  });

  const [isFirstUse, setIsFirstUse] = useState(() => {
    return !localStorage.getItem('userData');
  });

  const [people, setPeople] = useState(() => {
    const savedPeople = localStorage.getItem('stoikPeople');
    console.log('Loading initial people from localStorage:', savedPeople);
    if (savedPeople) {
      const parsedPeople = JSON.parse(savedPeople);
      if (parsedPeople && parsedPeople.length > 0) {
        return parsedPeople.map(person => ({
          ...person,
          id: person.id || uuidv4(),
          moments: (person.moments || []).map(moment => ({
            ...moment,
            id: moment.id || uuidv4()
          }))
        }));
      }
    }
    return [];
  });

  useEffect(() => {
   // console.log('Saving people to localStorage:', JSON.stringify(people));
    localStorage.setItem('stoikPeople', JSON.stringify(people));
  }, [people]);

  useEffect(() => {
    if (userData) {
      localStorage.setItem('userData', JSON.stringify(userData));
    }
  }, [userData]);

  useEffect(() => {
    const cleanupStoredData = () => {
      const storedPeople = localStorage.getItem('stoikPeople');
      if (storedPeople) {
        const parsedPeople = JSON.parse(storedPeople);
        const cleanedPeople = parsedPeople.filter(person => person.email !== userData?.email);
        localStorage.setItem('stoikPeople', JSON.stringify(cleanedPeople));
        setPeople(cleanedPeople);
      }
    };

    cleanupStoredData();
  }, [userData]);

  useEffect(() => {
    const updatedPeople = updatePeopleWithPastMoments(people);
    setPeople(updatedPeople);
  }, []);

  useEffect(() => {
    const checkUnratedPastMoments = () => {
      const newNotifications = [];

      console.log('Checking for unrated past moments in pastEvents...');
      
      people.forEach(person => {
        console.log('Checking person:', person.name, 'pastEvents:', person.pastEvents);
        
        (person.pastEvents || []).forEach(moment => {
          // Check if we already have a notification for this moment
          const existingNotification = notifications.find(
            n => n.type === 'rate_moment' && 
                n.data.momentId === moment.id
          );

          // Only create notification if moment is unrated and we don't already have a notification
          if (!moment.rating && !existingNotification) {
            console.log('Found new unrated past moment:', moment);
            
            newNotifications.push({
              id: uuidv4(),
              type: 'rate_moment',
              title: 'Rate Your Past Moment',
              message: `How was your ${moment.momentType} moment with ${person.name}?`,
              createdAt: new Date(),
              data: { 
                momentId: moment.id, 
                personId: person.id,
                moment: moment
              },
              read: false
            });
          }
        });
      });

      // Only add new notifications if we found any
      if (newNotifications.length > 0) {
        console.log('Adding new notifications:', newNotifications);
        setNotifications(prev => {
          const updated = [...newNotifications, ...prev];
          console.log('Updated notifications:', updated);
          return updated;
        });
        setUnreadNotifications(prev => [...newNotifications, ...prev]);
      }
    };

    checkUnratedPastMoments();
    const interval = setInterval(checkUnratedPastMoments, 60 * 60 * 1000);
    return () => clearInterval(interval);
  }, [people, notifications]);

  const sortedPeople = useMemo(() => {
    return (people || []).sort((a, b) => {
      const aNextEvent = a.events && a.events[0] ? new Date(a.events[0].date) : new Date(9999, 11, 31);
      const bNextEvent = b.events && b.events[0] ? new Date(b.events[0].date) : new Date(9999, 11, 31);
      return aNextEvent - bNextEvent;
    });
  }, [people]);

  const handleAddEvent = useCallback((prefillData = null) => {
    console.log('handleAddMoment called with prefillData:', prefillData);
    setPrefillMomentData(prefillData);
    setIsAddMomentModalOpen(true);
  }, []);

  const handleAddMoment = useCallback((newMoment) => {
    console.log('Adding new moment YAS:', newMoment);
    if (newMoment && newMoment.person) {
      setPeople(prevPeople => {
        const personIndex = (prevPeople || []).findIndex(p => p.name === newMoment.person);
        if (personIndex !== -1) {
          const updatedPeople = [...prevPeople];
          const updatedPerson = {
            ...updatedPeople[personIndex],
            moments: [
              ...(updatedPeople[personIndex].moments || []),
              {
                id: uuidv4(),
                date: newMoment.date,
                momentType: newMoment.momentType,
                notes: newMoment.notes || '',
                title: newMoment.title || '',
                effortLevel: newMoment.effortLevel || '',
                isPastEvent: false
              }
            ]
          };
          updatedPeople[personIndex] = updatePersonWithRelationshipHealth(updatedPerson);
          return updatedPeople;
        } else {
          const newPerson = {
            id: uuidv4(),
            name: newMoment.person,
            moments: [{
              id: uuidv4(),
              date: newMoment.date,
              momentType: newMoment.momentType,
              notes: newMoment.notes || '',
              title: newMoment.title || '',
              effortLevel: newMoment.effortLevel || '',
              isPastEvent: false
            }]
          };
          return [...(prevPeople || []), updatePersonWithRelationshipHealth(newPerson)];
        }
      });

      trackEvent('create_moment', {
        moment_type: newMoment.momentType,
        person_name: newMoment.person,
        effort_level: newMoment.effortLevel
      });
    }
    setIsAddMomentModalOpen(false);
    setIsFirstUse(false);
  }, []);

  const handleNotificationClick = useCallback((notification) => {
    // Mark notification as read
    setNotifications(prev =>
      prev.map(n =>
        n.id === notification.id ? { ...n, read: true } : n
      )
    );
    setUnreadNotifications(prev => 
      prev.filter(n => n.id !== notification.id)
    );

    // Handle different notification types
    switch (notification.type) {
      case 'rate_moment': {
        const targetPerson = people.find(p => p.id === notification.data.personId);
        if (targetPerson) {
          // Only set momentToRate if the moment hasn't been rated yet
          const moment = notification.data.moment;
          if (!moment.rating) {
            setMomentToRate({
              ...moment,
              person: targetPerson.name
            });
          } else {
            console.log('Moment already rated:', moment);
          }
        }
        break;
      }
      // ... other cases ...
    }
    
    setIsNotificationCenterOpen(false);
  }, [people]);

  const updatePerson = useCallback((updatedPerson) => {
    console.log('Updating person:', updatedPerson);
    setPeople(prevPeople => {
      const personIndex = prevPeople.findIndex(p => p.id === updatedPerson.id);
      if (personIndex !== -1) {
        const personWithUpdatedHealth = updatePersonWithRelationshipHealth(updatedPerson);
        const newPeople = prevPeople.map(p => p.id === updatedPerson.id ? personWithUpdatedHealth : p);
        
        trackEvent('edit_person', {
          person_id: updatedPerson.id,
          person_name: updatedPerson.name,
          fields_updated: Object.keys(updatedPerson).join(',')
        });

        return newPeople;
      } else {
        const newPersonWithHealth = updatePersonWithRelationshipHealth(updatedPerson);
        return [...prevPeople, newPersonWithHealth];
      }
    });
    setSelectedPerson(null);
  }, []);

  const handleOpenAddMomentModal = useCallback(() => {
    console.log('handleOpenAddMomentModal called');
    setShowOnboarding(true);
  }, []);

  const handleUserOnboardingSubmit = useCallback((data) => {
    console.log('handleUserOnboardingSubmit called with data:', data);
    setUserData({
      ...data,
      analyticsConsent: data.analyticsConsent
    });
    setIsAddMomentModalOpen(true);
    setShowOnboarding(false);
    setIsFirstUse(false);

    if (data.analyticsConsent) {
      trackEvent('sign_up', {
        method: 'onboarding'
      });

      setUserProperties({
        user_id: data.email,
        zipcode: data.zipCode
      });
    }
  }, []);

  const handleOnboardingComplete = useCallback((data) => {
    console.log('Onboarding completed with data:', data);
    setUserData(data.userData);
    
    setPeople(prevPeople => {
      let updatedPerson = data.personData;
      
      if (data.selectedSuggestion) {
        updatedPerson = {
          ...updatedPerson,
          moments: [
            ...(updatedPerson.moments || []),
            {
              id: uuidv4(),
              date: data.selectedSuggestion.date,
              momentType: data.selectedSuggestion.type,
              notes: data.selectedSuggestion.suggestion,
              title: data.selectedSuggestion.suggestion,
              effortLevel: data.selectedSuggestion.effortLevel,
              isPastEvent: false
            }
          ]
        };
      }

      const existingPersonIndex = prevPeople.findIndex(p => p.id === updatedPerson.id);
      if (existingPersonIndex !== -1) {
        return prevPeople.map(p => p.id === updatedPerson.id ? updatedPerson : p);
      } else {
        return [...prevPeople, updatedPerson];
      }
    });

    setIsFirstUse(false);
    setSelectedPerson(null);
    
    trackEvent('onboarding_complete', {
      user_id: data.userData.email
    });
  }, []);

  const addMoment = useCallback((newMoment) => {
    console.log('Adding new moment:', newMoment);
    if (newMoment && newMoment.person) {
      setPeople(prevPeople => {
        const personIndex = (prevPeople || []).findIndex(p => p.name === newMoment.person);
        if (personIndex !== -1) {
          const updatedPeople = [...prevPeople];
          const updatedPerson = {
            ...updatedPeople[personIndex],
            moments: [
              ...(updatedPeople[personIndex].moments || []),
              {
                id: uuidv4(),
                date: newMoment.date,
                momentType: newMoment.momentType,
                notes: newMoment.notes || '',
                title: newMoment.title || '',
                effortLevel: newMoment.effortLevel || '',
                isPastEvent: false
              }
            ]
          };
          updatedPeople[personIndex] = updatePersonWithRelationshipHealth(updatedPerson);
          return updatedPeople;
        } else {
          const newPerson = {
            id: uuidv4(),
            name: newMoment.person,
            moments: [{
              id: uuidv4(),
              date: newMoment.date,
              momentType: newMoment.momentType,
              notes: newMoment.notes || '',
              title: newMoment.title || '',
              effortLevel: newMoment.effortLevel || '',
              isPastEvent: false
            }]
          };
          return [...(prevPeople || []), updatePersonWithRelationshipHealth(newPerson)];
        }
      });

      trackEvent('create_moment', {
        moment_type: newMoment.momentType,
        person_name: newMoment.person,
        effort_level: newMoment.effortLevel
      });
    }
    setIsAddMomentModalOpen(false);
    setIsFirstUse(false);
  }, []);

  const updateMoment = useCallback((updatedMoment) => {
    console.log('Updating moment:', updatedMoment);
    setPeople(prevPeople => {
      const newPeople = prevPeople.map(person => {
        if (person.id === updatedMoment.personId) {
          const updatedMoments = person.moments.map(moment => 
            moment.id === updatedMoment.id ? updatedMoment : moment
          );
          const updatedPerson = { ...person, moments: updatedMoments };
          return updatePersonWithRelationshipHealth(updatedPerson);
        }
        return person;
      });

      trackEvent('edit_moment', {
        moment_id: updatedMoment.id,
        moment_type: updatedMoment.momentType,
        person_name: updatedMoment.person,
        effort_level: updatedMoment.effortLevel
      });

      return newPeople;
    });
  }, []);

  const addPerson = useCallback(() => {
    const newPerson = { id: uuidv4(), name: 'New Person', events: [] };
    console.log('Adding new person:', newPerson);
    setPeople(prevPeople => [...prevPeople, newPerson]);
    setSelectedPerson(newPerson);

    trackEvent('create_person', {
      person_id: newPerson.id
    });
  }, []);

  const deletePerson = useCallback((personId) => {
    console.log('Deleting person with ID:', personId);
    setPeople(prevPeople => {
      const newPeople = prevPeople.filter(p => p.id !== personId);
      console.log('New people state after deletion:', newPeople);
      return newPeople;
    });
    setSelectedPerson(null);
  }, []);

  const clearAllData = useCallback(() => {
    console.log('Clearing all data');
    setPeople([]);
    localStorage.removeItem('stoikPeople');
    localStorage.removeItem('userData');
    setSelectedPerson(null);
    setIsFirstUse(true);
    setUserData(null);
    setShowOnboarding(false);
  }, []);

  const getPageBgColor = useCallback(() => {
    switch (activeTab) {
      case 'people': return '#FFFACD';
      case 'gifts': return '#E6E6FF';
      default: return '#EFBEE7';
    }
  }, [activeTab]);

  const handleUpdateUserData = (updatedData) => {
    setUserData(updatedData);
    localStorage.setItem('userData', JSON.stringify(updatedData));
  };

  const handleRateMoment = useCallback(({ rating, notes }) => {
    if (!momentToRate) return;

    setPeople(prevPeople => {
      return prevPeople.map(person => {
        const updatedPastEvents = (person.pastEvents || []).map(moment => {
          if (moment.id === momentToRate.id) {
            return {
              ...moment,
              rating,
              ratingNotes: notes
            };
          }
          return moment;
        });

        return {
          ...person,
          pastEvents: updatedPastEvents
        };
      });
    });

    // Track the rating
    trackEvent('rate_moment', {
      moment_id: momentToRate.id,
      rating,
      has_notes: !!notes
    });

    setMomentToRate(null);
  }, [momentToRate]);

  console.log('Render state:', { isFirstUse, showOnboarding, peopleLength: people.length });

  if (showTemplatePreview) {
    return (
      <div className="bg-gradient-to-b from-pink-200 to-purple-200 min-h-screen">
        <TemplatePreview onBack={() => setShowTemplatePreview(false)} />
      </div>
    );
  }

  if (isFirstUse) {
    return <OnboardingFlow onComplete={handleOnboardingComplete} onUpdatePerson={updatePerson} />;
  }

  console.log('Rendering main app interface');
  return (
    <div className="bg-gradient-to-b from-pink-200 to-purple-200 min-h-screen p-4 max-w-md mx-auto font-sans">
      <div className="flex justify-between items-center mb-6" style={{ marginTop: '59px' }}>
        <h1 className="text-2xl font-bold">humanBetter</h1>
        <div className="flex items-center gap-2">
          <NotificationBell 
            unreadCount={unreadNotifications.length} 
            onClick={() => setIsNotificationCenterOpen(true)} 
          />
          <button
            onClick={() => setIsFeedbackModalOpen(true)}
            className="w-[40px] h-[40px] rounded-full bg-white/80 text-gray-600 flex items-center justify-center hover:bg-white"
            aria-label="Send Feedback"
          >
            <MessageSquare size={20} />
          </button>
          <button
            onClick={() => setIsSettingsModalOpen(true)}
            className="w-[40px] h-[40px] rounded-full bg-white/80 text-gray-600 flex items-center justify-center hover:bg-white"
            aria-label="Settings"
          >
            <Settings size={20} />
          </button>
          {userData?.email === "sean.net@gmail.com" && (
            <button
              onClick={() => setShowTemplatePreview(true)}
              className="w-[40px] h-[40px] rounded-full bg-yellow-200 text-gray-600 flex items-center justify-center hover:bg-yellow-300 transition-colors"
              aria-label="View Templates"
            >
              <Mail size={20} />
            </button>
          )}
          <button
            onClick={addPerson}
            className="w-[40px] h-[40px] rounded-full bg-white/80 text-gray-600 flex items-center justify-center hover:bg-white group relative"
            aria-label="Add Person"
          >
            <Users size={20} />
            <Plus size={12} className="absolute -top-1 -right-1 bg-indigo-600 text-white rounded-full p-0.5" />
            <span className="absolute -bottom-8 bg-gray-800 text-white text-xs py-1 px-2 rounded opacity-0 group-hover:opacity-100 transition-opacity whitespace-nowrap">
              Add Person
            </span>
          </button>
          <button
            onClick={handleAddEvent}
            className="w-[50px] h-[50px] rounded-full bg-white text-black flex items-center justify-center hover:bg-white/90 group relative"
            aria-label="Add Moment"
          >
            <Calendar size={24} />
            <Plus size={14} className="absolute -top-1 -right-1 bg-indigo-600 text-white rounded-full p-0.5" />
            <span className="absolute -bottom-8 bg-gray-800 text-white text-xs py-1 px-2 rounded opacity-0 group-hover:opacity-100 transition-opacity whitespace-nowrap">
              Add Moment
            </span>
          </button>
        </div>
      </div>
      
      {activeTab === 'moments' && (
        <MomentsPage 
          currentDate={currentDate} 
          setCurrentDate={setCurrentDate} 
          people={people} 
          onPersonClick={(person) => {
            console.log('Person clicked:', person);
            setSelectedPerson(person);
          }}
          onAddPerson={addPerson}
          onUpdatePerson={updatePerson}
          onUpdateMoment={updateMoment}
          onClearAllData={clearAllData}
          onAddMoment={handleAddMoment}
          momentTypes={momentTypes}
        />
      )}
      {activeTab === 'people' && (
        <div className="mb-8">
          <h2 className="text-sm font-medium text-gray-700 mb-2">People</h2>
          <PeoplePage 
            people={sortedPeople || []} 
            onAddPerson={addPerson}
            onPersonClick={setSelectedPerson}
            onAddMoment={() => setIsAddMomentModalOpen(true)}
          />
        </div>
      )}
      {activeTab === 'gifts' && <GiftsPage />}
      
      <AddMomentModal 
        isOpen={isAddMomentModalOpen} 
        onClose={() => {
          setIsAddMomentModalOpen(false);
          setPrefillMomentData(null);
        }}
        onAddMoment={addMoment}
        people={people || []}
        momentTypes={momentTypes || []}
        prefillData={prefillMomentData}
        isFirstUse={isFirstUse}
      />

      {selectedPerson && (
        <PersonDetailsModal
          isOpen={!!selectedPerson}
          onClose={() => {
            console.log('Closing PersonDetailsModal');
            setSelectedPerson(null);
          }}
          person={selectedPerson}
          onUpdatePerson={updatePerson}
          onDeletePerson={deletePerson}
          momentTypes={momentTypes || []}
          isOnboarding={false}
        />
      )}

      <SettingsModal
        isOpen={isSettingsModalOpen}
        onClose={() => setIsSettingsModalOpen(false)}
        userData={userData}
        onUpdateUserData={handleUpdateUserData}
        onClearAllData={clearAllData}
      />

      {isFeedbackModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
          <div className="bg-white rounded-lg max-w-md w-full p-6">
            <h3 className="text-lg font-medium mb-4">Send Feedback</h3>
            <form 
              onSubmit={(e) => {
                e.preventDefault();
                const formData = new FormData(e.target);
                const feedback = {
                  type: formData.get('type'),
                  message: formData.get('message')
                };
                
                trackEvent('submit_feedback', { type: feedback.type });
                
                window.location.href = `mailto:sean.net@gmail.com?subject=Moments App ${feedback.type}&body=${encodeURIComponent(feedback.message)}`;
                
                setIsFeedbackModalOpen(false);
              }}
              className="space-y-4"
            >
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Type
                </label>
                <select 
                  name="type"
                  className="w-full border rounded-md p-2"
                  required
                >
                  <option value="feature_request">Feature Request</option>
                  <option value="bug_report">Bug Report</option>
                  <option value="general_feedback">General Feedback</option>
                </select>
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Message
                </label>
                <textarea
                  name="message"
                  rows={4}
                  className="w-full border rounded-md p-2"
                  required
                  placeholder="Tell us what's on your mind..."
                />
              </div>
              <div className="flex justify-end gap-2">
                <button
                  type="button"
                  onClick={() => setIsFeedbackModalOpen(false)}
                  className="px-4 py-2 text-gray-600 hover:bg-gray-100 rounded"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="px-4 py-2 bg-indigo-600 text-white rounded hover:bg-indigo-700"
                >
                  Send Feedback
                </button>
              </div>
            </form>
          </div>
        </div>
      )}

      <NotificationCenter 
        isOpen={isNotificationCenterOpen}
        onClose={() => setIsNotificationCenterOpen(false)}
        notifications={notifications}
        onNotificationClick={handleNotificationClick}
      />

      <RatingModal
        isOpen={!!momentToRate}
        onClose={() => setMomentToRate(null)}
        moment={momentToRate}
        onRate={handleRateMoment}
      />

      <div className="fixed bottom-2 left-2 text-xs text-gray-400 select-none">
        {APP_VERSION}
      </div>
    </div>
  );
};

export default App;