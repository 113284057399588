import React, { useState, useMemo, useCallback, useEffect } from 'react';
import { ChevronLeft, ChevronRight, Plus, Gem, Mountain, Utensils, Calendar, Settings, MessageSquare } from 'lucide-react';
import { startOfDay, addDays, format, isToday, isSameDay, parseISO, differenceInDays } from 'date-fns';
import { v4 as uuidv4 } from 'uuid';
import PeopleCards from './PeopleCards';
import MomentDetailsModal from './MomentDetailsModal';
import generateMomentSuggestions from './generateMomentSuggestions';
import MomentSuggestions from './MomentSuggestions';
import NextUpCard from './NextUpCard';
import { trackEvent } from './utils/analytics';
import EditMomentModal from './EditMomentModal';
import SettingsModal from './SettingsModal';
import AddMomentModal from './AddMomentModal';

const MomentsPage = ({ 
  currentDate, 
  setCurrentDate, 
  people = [], 
  onPersonClick, 
  onAddPerson, 
  onUpdatePerson,
  onUpdateMoment,
  onClearAllData,
  onAddMoment,
  momentTypes
}) => {
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedMoments, setSelectedMoments] = useState([]);
  const [suggestions, setSuggestions] = useState([]);
  const [currentUpcomingIndex, setCurrentUpcomingIndex] = useState(0);
  const [prefillMomentData, setPrefillMomentData] = useState(null);
  const [editingMoment, setEditingMoment] = useState(null);
  const [isSettingsModalOpen, setIsSettingsModalOpen] = useState(false);
  const [isFeedbackModalOpen, setIsFeedbackModalOpen] = useState(false);
  const [userData, setUserData] = useState(() => {
    const savedUserData = localStorage.getItem('userData');
    console.log('Initial userData from localStorage:', savedUserData);
    return savedUserData ? JSON.parse(savedUserData) : null;
  });
  const [isDetailsModalOpen, setIsDetailsModalOpen] = useState(false);
  const [isAddMomentModalOpen, setIsAddMomentModalOpen] = useState(false);

  const getMomentsForDay = useCallback((date) => {
    return people.flatMap(person => 
      (person.moments || []).filter(moment => 
        isSameDay(parseISO(moment.date), date)
      ).map(moment => ({...moment, personName: person.name, personId: person.id}))
    );
  }, [people]);

  const upcomingMoments = useMemo(() => {
    const today = startOfDay(new Date());
    return people
      .flatMap(person => 
        (person.moments || []).map(moment => ({
          ...moment,
          personName: person.name,
          personId: person.id,
          daysUntil: differenceInDays(parseISO(moment.date), today)
        }))
      )
      .filter(moment => moment.daysUntil >= 0)
      .sort((a, b) => a.daysUntil - b.daysUntil);
  }, [people]);

  const handleDateClick = useCallback((date) => {
    console.log('Date clicked:', date);
    const momentsForDay = getMomentsForDay(date);
    
    if (momentsForDay.length > 0) {
      // Open EditMomentModal for the first moment of the day
      setEditingMoment(momentsForDay[0]);
    }

    trackEvent('view_calendar_date', {
      date: format(date, 'yyyy-MM-dd')
    });
  }, [getMomentsForDay]);

  useEffect(() => {
    try {
      const newSuggestions = generateMomentSuggestions(people, currentDate);
      setSuggestions(newSuggestions);
    } catch (error) {
      console.error('Error generating suggestions:', error);
      setSuggestions([]);
    }
  }, [people, currentDate]);

  const handleSuggestionAction = (suggestion) => {
    console.log('Received suggestion:', suggestion);
    
    const momentType = suggestion.loveLanguage.charAt(0).toUpperCase() + suggestion.loveLanguage.slice(1);
    
    const prefillData = {
      date: suggestion.date || new Date().toISOString().split('T')[0],
      momentType: momentType,
      person: people.find(p => p.id === suggestion.personId)?.name || '',
      suggestion: suggestion.suggestion,
      notes: suggestion.suggestion,
      effortLevel: suggestion.effortLevel
    };
    
    setPrefillMomentData(prefillData);
    setIsAddMomentModalOpen(true);

    trackEvent('use_suggestion', {
      suggestion_type: suggestion.type,
      person_name: prefillData.person,
      effort_level: suggestion.effortLevel
    });
  };

  const renderWeek = () => {
    let days = [];
    const today = startOfDay(new Date());
    for (let i = 0; i < 7; i++) {
      const day = addDays(currentDate, i);
      const moments = getMomentsForDay(day);
      const hasEvent = moments.length > 0;
      const isCurrentDay = isSameDay(day, today);
      const isSelected = selectedDate && isSameDay(day, selectedDate);

      let bgColor = 'bg-white';
      let textColor = 'text-gray-700';
      
      if (isCurrentDay) {
        bgColor = 'bg-indigo-100';
        textColor = 'text-indigo-800';
      }
      
      if (hasEvent) {
        bgColor = 'bg-purple-500';
        textColor = 'text-white';
      }

      if (isSelected) {
        bgColor = 'bg-indigo-600';
        textColor = 'text-white';
      }

      const baseClasses = `text-xs w-7 h-7 ${bgColor} rounded-full flex items-center justify-center ${textColor}`;
      const clickableClasses = hasEvent ? 'cursor-pointer hover:bg-opacity-80' : '';

      days.push(
        <div 
          key={day.toString()} 
          className={`${baseClasses} ${clickableClasses}`}
          onClick={hasEvent ? () => handleDateClick(day) : undefined}
          aria-label={hasEvent ? `Select ${format(day, 'MMMM d, yyyy')}` : undefined}
          role={hasEvent ? "button" : undefined}
        >
          {format(day, 'd')}
        </div>
      );
    }
    return days;
  };

  const handlePreviousWeek = () => {
    setCurrentDate(prev => addDays(prev, -7));
  };

  const handleNextWeek = () => {
    setCurrentDate(prev => addDays(prev, 7));
  };

  const renderNextUp = () => {
    if (upcomingMoments.length === 0) {
      return <p className="text-sm text-gray-600">No upcoming moments</p>;
    }
  
    return (
      <div className="relative overflow-hidden" style={{ height: '390px' }}>
        <div 
          className="flex transition-transform duration-300 ease-in-out" 
          style={{ transform: `translateX(-${currentUpcomingIndex * 298}px)` }}
        >
          {upcomingMoments.map((moment, index) => (
            <NextUpCard
              key={index}
              moment={moment}
              onViewDetails={handleDateClick}
              onUpdateMoment={onUpdateMoment}
              people={people}
              momentTypes={momentTypes}
            />
          ))}
        </div>
        {upcomingMoments.length > 1 && (
          <>
            <button 
              onClick={() => setCurrentUpcomingIndex(prev => Math.max(0, prev - 1))}
              className="absolute left-0 top-1/2 transform -translate-y-1/2 z-10"
              disabled={currentUpcomingIndex === 0}
            >
              <ChevronLeft 
                size={24} 
                className={currentUpcomingIndex === 0 ? "text-gray-300" : "text-indigo-600"} 
              />
            </button>
            <button 
              onClick={() => setCurrentUpcomingIndex(prev => Math.min(upcomingMoments.length - 1, prev + 1))}
              className="absolute right-0 top-1/2 transform -translate-y-1/2 z-10"
              disabled={currentUpcomingIndex === upcomingMoments.length - 1}
            >
              <ChevronRight 
                size={24} 
                className={currentUpcomingIndex === upcomingMoments.length - 1 ? "text-gray-300" : "text-indigo-600"} 
              />
            </button>
          </>
        )}
      </div>
    );
  };

  const handleUpdateUserData = (updatedData) => {
    console.log('Updating user data with:', updatedData);
    setUserData(updatedData);
    localStorage.setItem('userData', JSON.stringify(updatedData));
  };

  const handleOpenSettings = () => {
    console.log('Opening settings modal with userData:', userData);
    setIsSettingsModalOpen(true);
  };

  const handleAddMoment = () => {
    setPrefillMomentData(null);
    setIsAddMomentModalOpen(true);
    trackEvent('open_new_moment_modal');
  };

  return (
    <div className="p-6">
      <div className="mb-4">
        <h2 className="text-sm font-medium text-gray-700 mb-2">{format(currentDate, 'MMMM yyyy')}</h2>
      </div>

      <div className="flex items-center justify-between mb-8">
        <button onClick={handlePreviousWeek} className="p-2 rounded-full hover:bg-gray-200">
          <ChevronLeft size={20} className="text-indigo-600" />
        </button>
        <div className="flex space-x-2">
          {renderWeek()}
        </div>
        <button onClick={handleNextWeek} className="p-2 rounded-full hover:bg-gray-200">
          <ChevronRight size={20} className="text-indigo-600" />
        </button>
      </div>

      <div className="mb-8">
        <h2 className="text-sm font-medium text-gray-700 mb-2">Next Up</h2>
        {renderNextUp()}
      </div>

      <div className="mb-8">
        <h2 className="text-sm font-medium text-gray-700 mb-2">Suggested</h2>
        <MomentSuggestions 
          suggestions={suggestions} 
          onSuggestionAction={handleSuggestionAction}
        />
      </div>

      <div className="mb-8">
        <h2 className="text-sm font-medium text-gray-700 mb-2">People</h2>
        <PeopleCards 
          people={people} 
          onPersonClick={onPersonClick} 
          onAddPerson={onAddPerson} 
        />
      </div>

      <SettingsModal
        isOpen={isSettingsModalOpen}
        onClose={() => setIsSettingsModalOpen(false)}
        userData={userData}
        onUpdateUserData={handleUpdateUserData}
        onClearAllData={onClearAllData}
      />

      {editingMoment && (
        <EditMomentModal
          isOpen={!!editingMoment}
          onClose={() => setEditingMoment(null)}
          moment={editingMoment}
          onUpdateMoment={(updatedMoment) => {
            onUpdateMoment(updatedMoment);
            setEditingMoment(null);
          }}
          people={people}
          momentTypes={momentTypes}
        />
      )}

      <MomentDetailsModal
        isOpen={isDetailsModalOpen}
        onClose={() => setIsDetailsModalOpen(false)}
        onAddMoment={onAddMoment}
        people={people}
        momentTypes={momentTypes}
        prefillData={prefillMomentData}
      />

      <AddMomentModal
        isOpen={isAddMomentModalOpen}
        onClose={() => {
          setIsAddMomentModalOpen(false);
          setPrefillMomentData(null);
        }}
        onAddMoment={(moment) => {
          onAddMoment(moment);
          setIsAddMomentModalOpen(false);
          setPrefillMomentData(null);
        }}
        people={people}
        momentTypes={momentTypes}
        prefillData={prefillMomentData}
        isFirstUse={false}
      />
    </div>
  );
};

export default MomentsPage;